import React from 'react'
import { Helmet } from 'react-helmet'

const SpellDetailPageHeader = ({name, school, castingTime, availableClasses, description, language}) => {
    const pageDescription = 'Dungeons and Dragons (D&D, DnD) Fifth Edition (5e) ' + name + 
        '. School: ' + school + '; Casting Time: ' + castingTime + '. Class: ' + availableClasses.join(', ') + '. ' + description.substring(0, 10);
    return (
        <Helmet>
            <title>{name} | DnD 5e Spells List</title>
            <meta name="description" content={pageDescription} />
            <meta httpEquiv="content-language" content={language} />
        </Helmet>
    )
}

export default SpellDetailPageHeader;