export const allClasses = [
    'Barbarian',
    'Bard',
    'Cleric',
    'Druid',
    'Fighter',
    'Monk',
    'Paladin',
    'Ranger',
    'Rogue',
    'Sorcerer',
    'Warlock',
    'Wizard'
];

export const allRaces = [
    'Dragonborn',
    'Dwarf',
    'Elf',
    'Gnome',
    'Half-Elf',
    'Halfling',
    'Half-Orc',
    'Human',
    'Tiefling'
];

export const allDamageTypes = [
    'Acid', 
    'Bludgeoning', 
    'Cold', 
    'Fire', 
    'Force', 
    'Lightning', 
    'Necrotic', 
    'Piercing', 
    'Poison', 
    'Psychic', 
    'Radiant', 
    'Slashing', 
    'Thunder'
]

export const allBooks = [
    'Elemental Evil',
    'Players Handbook',
    'Sword Coast Adventure Guide',
    'Xanathars Guide To Everything'
]

export const getClassKeyFromTranslation = (rawClass) => {
    switch(rawClass) {
        case 'Barbaro': return 'Barbarian';
        case 'Bardo': return 'Bard';
        case 'Chierico': return 'Cleric';
        case 'Druido': return 'Druid';
        case 'Guerriero': return 'Fighter';
        case 'Monaco': return 'Monk';
        case 'Paladino': return 'Paladin';
        case 'Ranger': return 'Ranger';
        case 'Ladro': return 'Rogue';
        case 'Stregone': return 'Sorcerer';
        case 'Warlock': return 'Warlock';
        case 'Mago': return 'Wizard';
        default: return rawClass;   //è già in inglese
    }
}