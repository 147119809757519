import React from 'react'
import SpellCard from './SpellCard'

export default function SpellCarousel({character, cards, type, characterSpellsChanged}) {
    return (
        <div className="viewport">
            <div className="carousel-frame">
                <div className="carousel">
                    <ul className="scroll">
                    {cards.map((x, i) => {
                        return (
                            <li key={x.name + '_outer_' + i} className="scroll-item-outer">
                                <div key={x.name + 'scroll_' + i} className="scroll-item">
                                    <SpellCard key={x.name + '_card_' + i} character={character} spell={x} type={type} characterSpellsChanged={characterSpellsChanged} />
                                </div>
                            </li>
                        )
                    })}
                    </ul>
                </div>
            </div>
        </div>
    )
}
