import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from '../../helpers/JsFunctions'
import { isAndroid } from "react-device-detect";  
import { translate } from '../multiLanguage/Translator';
import { colors } from '../../helpers/Theme';
import appData from '../../data/AppData'

function MobileAppSuggest() {
    const [open, setOpen] = React.useState(false);
    const [userClosed, setUserClosed] = React.useState(false);
    const [isApp, setIsApp] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const isMobile = useMediaQuery({query: isMobileQuery});

    const handleClose = () => {
        setOpen(false);
        setUserClosed(true);
    }
    const handleConfirm = () => {
        //aggiungere link al play store
        handleClose();
        window.open('https://play.google.com/store/apps/details?id=com.qubitart.dndspellslist', '_blank');
    }

    if(isLoading === true) {
        appData.whenReady(() => {
            if(isApp !== appData.isApp)
                setIsApp(appData.isApp);
            setIsLoading(false);
        })
    }

    if(isMobile && isAndroid && !open && !userClosed) setOpen(true);

    if(isMobile && isAndroid && !isApp && !isLoading) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">{translate('mobileSuggest.title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{translate('mobileSuggest.text')}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} style={{fontSize: 10}}>{translate('mobileSuggest.abort')}</Button>
                        <Button onClick={handleConfirm} style={{color: colors.red}} autoFocus>{translate('mobileSuggest.confirm')}</Button>
                    </DialogActions>
            </Dialog>
        )
    } else 
        return <React.Fragment />
}

export default MobileAppSuggest
