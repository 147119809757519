import React, { Component } from 'react'
import userData from '../../data/UserData'
import { Card, CardHeader, CardActionArea, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Avatar, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from '../../helpers/JsFunctions'

import NewCharacter from './dialogs/NewCharacter';
import EditCharacterStats from './dialogs/EditCharacterStats'
import EditCharacter from './EditCharacter'
import { translate, getStringTranslation } from '../multiLanguage/Translator'

export class CharacterManager extends Component {
    state = {
        characters: []
    }

    createNewCharacter = () => this.showCreatePage();

    editCharacter = (character) => this.showEditPage(character);
    editCharacterStats = (character) => this.showEditStatPage(character);

    refreshCharacters = (characterName) => {
        this.closeEditor();
        this.setState({characters: userData.getAllCharacters()});

        if(characterName !== undefined) {
            let character = userData._getCharacter(characterName);
            if(character !== null) {
                this.showEditPage(character);
                this.forceCharacterSelectionOpen(false);
            }
        } else {
            this.forceCharacterSelectionOpen(true);
        }
    }

    componentDidMount() {
        userData.whenReady(() => {
            this.refreshCharacters();
        })
    }

    render() {
        const { characters } = this.state;
        return (
            <React.Fragment>
                <CharacterManagerUI 
                    characters={characters} 
                    createNewCharacter={this.createNewCharacter} 
                    editCharacter={this.editCharacter}
                    forceOpen={f => this.forceCharacterSelectionOpen = f} />
                <NewCharacter 
                    showPage={s => this.showCreatePage = s} 
                    refreshCharacters={this.refreshCharacters} />
                <EditCharacterStats 
                    showPage={s => this.showEditStatPage = s} 
                    refreshCharacters={this.refreshCharacters} />
                <EditCharacter 
                    showPage={s => this.showEditPage = s} 
                    editCharacterStats={this.editCharacterStats}
                    forceClose={f => this.closeEditor = f} />
            </React.Fragment>
        )
    }
}


const CharacterManagerUI = ({characters, createNewCharacter, editCharacter, forceOpen}) => {
    const [isOpen, setOpen] = React.useState(true);
    const isMobile = useMediaQuery({query: isMobileQuery});

    const handleChange = () => setOpen(!isOpen);
    forceOpen((isOpen) => setOpen(isOpen));

    const characterClicked = (item) => {
        if(!isMobile) setOpen(false);
        editCharacter(item);
    }

    const body = (
        <Grid container justify='center'>
            {characters.map((item, index) => {
                const subheader = item.race + ' ' + item.classes.map(x => x.name).join(', ');
                const level = item.classes.map(x => x.level).reduce((a, b) => a + b);
                const color = item.classes.length === 1 ? item.classes[0].color : 'linear-gradient(45deg, ' + item.classes.map(x => x.color).join(', ') + ')';
                return (
                    <Grid item key={index}>
                        <Card style={characterCard}>
                            <CardActionArea onClick={() => characterClicked(item)}>
                                <CardHeader 
                                    title={<Typography variant="h5">{item.name}</Typography>}
                                    subheader={subheader} 
                                    avatar={<Avatar style={{background: color}}>{level}</Avatar>} />
                            </CardActionArea>
                        </Card>
                    </Grid>
                )
            })}
            <Grid item>
                <Card style={characterCard}>
                    <CardActionArea onClick={createNewCharacter}>
                        <CardHeader 
                            title={getStringTranslation('editCharacter.newCharacterTitle')} 
                            subheader={getStringTranslation('editCharacter.newCharacterDesc')} />
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
    );

    if(isMobile) {
        return body;
    } else {
        return (
            <ExpansionPanel expanded={isOpen} onChange={handleChange}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{fontSize: 16}}>
                    {translate('character.characterSelectLabel')}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {body}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }
}


const characterCard = {
    width: 250,
    textAlign: 'left',
    margin: 10
}

export default CharacterManager
