import React from 'react'
import { Grid, Button, IconButton, Toolbar, Drawer, SwipeableDrawer, List, ListItem, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import LocaleSwitcher from '../multiLanguage/LocaleSwitcher'
import { colors } from '../../helpers/Theme'
import { isMobileQuery } from '../../helpers/JsFunctions'

const HomeLink = React.forwardRef((props, ref) => (
    <Link innerRef={ref} to="/" {...props} />
));

const GuideLink = React.forwardRef((props, ref) => (
    <Link innerRef={ref} to="/guide" {...props} />
));

const AboutLink = React.forwardRef((props, ref) => (
    <Link innerRef={ref} to="/about" {...props} />
));

const ContactLink = React.forwardRef((props, ref) => (
    <Link innerRef={ref} to="/contact" {...props} />
));

const CharacterLink = React.forwardRef((props, ref) => (
    <Link innerRef={ref} to="/characterManager" {...props} />
));

const PrivacyLink = React.forwardRef((props, ref) => (
    <Link innerRef={ref} to="/privacy" {...props} />
));

const SiteHeader = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const isMobile = useMediaQuery({query: isMobileQuery});
    if(isMobile === false) {
        return (
            <header>
                <Grid container alignItems="flex-start" style={{backgroundColor: colors.darkGray}}>
                    <Grid item sm={4}>
                        <div className="header-title-container">
                            <Button component={HomeLink} style={{color: colors.gray, textTransform: 'none', fontWeight: 'bold', fontSize: 18}}>D&D Spells List</Button>
                        </div>
                    </Grid>
                    <Grid item sm={3} style={{textAlign: 'right'}}>
                        <Button component={CharacterLink} style={{marginLeft: 20, color: colors.gray, fontWeight: 'bold'}}>Character manager</Button>
                    </Grid>
                    <Grid item sm={1}>
                        <Button component={AboutLink} style={{color: colors.gray}}>About</Button>
                    </Grid>
                    <Grid item sm={1}>
                        <Button component={GuideLink} style={{color: colors.gray}}>Guide</Button>
                    </Grid>
                    <Grid item sm={1}>
                        <Button component={ContactLink} style={{color: colors.gray}}>Contact</Button>
                    </Grid>
                    <Grid item sm={1}>
                        <Button component={PrivacyLink} style={{color: colors.gray}}>Privacy</Button>
                    </Grid>
                    <Grid item sm={1}>
                        <LocaleSwitcher changeLocale={props.changeLocale} />
                    </Grid>
                </Grid>
            </header>
        );
    } else {
        return (
            <header>
                <Toolbar className="header-title-container header-container">
                    <Typography variant='h6' style={{flexGrow: 1, color: colors.gray, textTransform: 'none', fontWeight: 'bold', fontSize: 16}}>
                        D&D Spells List
                    </Typography>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}>
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <SwipeableDrawer anchor="left" open={open} onOpen={handleDrawerOpen} onClose={handleDrawerClose}>
                    <div role="presentation" style={{backgroundColor: colors.darkGray, height: '100%'}}>
                        <List>
                            <ListItem>
                                <Link to="/" style={{...linkButton, ...{fontWeight: 'bold'}}}>Home</Link>
                            </ListItem>
                            <ListItem>
                                <Link to="/characterManager" style={{...linkButton, ...{fontWeight: 'bold'}}}>Character manager</Link>
                            </ListItem>
                            <ListItem>
                                <Link to="/about" style={linkButton}>About</Link>
                            </ListItem>
                            <ListItem>
                                <Link to="/guide" style={linkButton}>Guide</Link>
                            </ListItem>
                            <ListItem>
                                <Link to="/contact" style={linkButton}>Contact</Link>
                            </ListItem>
                            <ListItem>
                                <Link to="/privacy" style={linkButton}>Privacy</Link>
                            </ListItem>
                            <ListItem>
                                <LocaleSwitcher changeLocale={props.changeLocale} />
                            </ListItem>
                        </List>
                    </div>
                </SwipeableDrawer>
            </header>
        )
    }
}

const linkButton = {
    color: colors.gray, 
    padding: '10px 0',
    width: '100%',
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    textDecoration: 'none'
}

export default SiteHeader
