//devo usare questo quando non posso usare il componente <Translate> che si accorge da solo che il testo è cambiato
//ad esempio negli array di configurazione per i filtri
const LocaleProvider = {
    get instance() {
        if(!this._instance) {
            this._instance = {
                _subscribers: [],

                subscribe(name, callback) {
                    let index = this._subscribers.findIndex(x => x.name === name);
                    if(index > -1) {
                        this._subscribers.splice(index, 1);
                    }
                    this._subscribers.push({name: name, callback: callback});
                },

                unSubscribe(name) {
                    let index = this._subscribers.findIndex(x => x.name === name);
                    if(index > -1) {
                        this._subscribers.splice(index, 1);
                    }
                },

                localeChanged(lang) {
                    for(let i = 0; i < this._subscribers.length; i++) {
                        this._subscribers[i].callback(lang);
                    }
                }
            };
        }
        return this._instance;
    }
}

export default LocaleProvider;