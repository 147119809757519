import dispatcher, { protocols } from '../communication/Dispatcher'

let instance = null;
class AppData {
    isApp = false;      //indica se l'app è in esecuzione dentro l'app mobile su un dispositivo
    _isReady = false;

    constructor() {
        if (!instance) {
            instance = this;
        }

        dispatcher.subscribe('AppData', this._messageReceived);
        setTimeout(() => {
            this._isReady = true;
        }, 2000);

        return instance;
    }

    whenReady = (callback) => {
        if(this._isReady) {
            callback();
            return;
        }
        setTimeout(() => {
            this.whenReady(callback);
        }, 100);
    }

    _messageReceived = (data, protocol) => {
        // alert(data)
        // alert(protocol)
        if(protocol === protocols.AppData) {
            this.isApp = data.isApp;
            this._isReady = true;
            //alert(this.isApp)
        }
    }
}

export default new AppData();