import React, { useEffect, useState } from 'react'
import { Paper } from '@material-ui/core'
import counterpart from 'counterpart'
import { translate } from '../multiLanguage/Translator'
import { colors } from '../../helpers/Theme'
import LocaleProvider from '../multiLanguage/LocaleProvider'

const SpellbookInstructions = () => {
    const [locale, setLocale] = useState(counterpart.getLocale())

    useEffect(() => {
        LocaleProvider.instance.subscribe('SpellbookInstructions', () => {
            setLocale(counterpart.getLocale())
        });
    }, [])

    return (
        <>
            <Paper style={instructions}>
                {translate('spellbook.longInstructions')}
            </Paper>
            <Paper style={{margin: '0 15px 25px', padding: 10, textAlign: 'left', backgroundColor: colors.lightGray}}>
                {locale === 'en' ? 
                    <p>
                        A big thanks to our sponsor: <strong>Sturppy</strong>. If your are a startup you should check out their <strong><i><a href='https://sturppy.com/' target='_blank' style={{textDecoration: 'none', color: 'black'}}>financial modeling tool</a></i></strong> made specifically for founders.
                    </p> : 
                    <p>
                        Un grande grazie al nostro sponsor: <strong>Sturppy</strong>. Se sei una startup dovresti dare un'occhiata alla loro <strong><i><a href='https://sturppy.com/' target='_blank' style={{textDecoration: 'none', color: 'black'}}>piattaforma per modelli finanziari</a></i></strong> creata per i fondatori di startup.
                    </p>}
            </Paper>
        </>
    )
}

const instructions = {
    margin: '25px 15px',
    padding: 10,
    textAlign: 'left',
    backgroundColor: colors.lighterGray,
}

export default SpellbookInstructions;