import React from 'react'
import { Chip } from '@material-ui/core'
import { MTableToolbar } from 'material-table'

import MultiSelect from '../controls/MultiSelect'
import { getStringTranslation } from '../multiLanguage/Translator'
import { colors } from '../../helpers/Theme'
import { allDamageTypes, allBooks } from '../../data/GameData'
import appData from '../../data/AppData'

const SpellsTableToolbar = ({ toolbarProps, toggleSelection, filterType, filteredDamageTypes, 
    showFavourites, isSelection, isOnlyFavourites, filterBook, selectedBook }) => {
    const [isApp, setIsApp] = React.useState(false);
    const damageTypes = allDamageTypes.map(x => getStringTranslation('spell.damageType.' + x.toLowerCase()));
    const books = allBooks.map(x => getStringTranslation('books.' + x.toLowerCase().split(' ').join(''))).filter(x => x !== '');

    appData.whenReady(() => {
        if(isApp !== appData.isApp)
            setIsApp(appData.isApp);
    });

    const toggleLabel = isSelection ? getStringTranslation('table.unToggleSelection') : getStringTranslation('table.toggleSelection');
    const favouriteLabel = isOnlyFavourites ? getStringTranslation('table.unFavouriteSelection') : getStringTranslation('table.favouriteSelection');

    return (
        <div style={{padding: '0px 10px'}}>
            <MTableToolbar {...toolbarProps} />
            {!isApp &&
                <Chip label={toggleLabel} clickable={true} color="secondary" onClick={toggleSelection} style={chipStyle} />}
            <Chip label={favouriteLabel} clickable={true} onClick={showFavourites} style={{...chipStyle, ...favouriteChip}} />
            <br />
            <MultiSelect 
                label={getStringTranslation('table.filterDamageType')} 
                id="filter-damage-type" 
                options={damageTypes} 
                filterType={filterType} 
                selected={filteredDamageTypes} />
            <MultiSelect
                label={getStringTranslation('table.filterBook')}
                id="filter-book"
                options={books}
                filterType={filterBook}
                selected={selectedBook} />
        </div>
    )
}

const chipStyle = {
    height: 36, 
    margin: '6px 10px'
}

const favouriteChip = {
    backgroundColor: colors.goldYellow
}

export default SpellsTableToolbar
