import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { translate } from '../multiLanguage/Translator'
import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from '../../helpers/JsFunctions'
import appData from '../../data/AppData'

const SiteFooter = () => {
    const [isApp, setIsApp] = React.useState(false);
    const isMobile = useMediaQuery({query: isMobileQuery});

    appData.whenReady(() => {
        if(isApp !== appData.isApp)
            setIsApp(appData.isApp);
    })

    const container = {
        backgroundColor: '#222',
        color: '#ccc', 
        marginTop: isMobile ? 10 : 20, 
        paddingLeft: isMobile ? 10 : 20,
        paddingRight: isMobile ? 10 : 40,
        paddingTop: 20,
        paddingBottom: 15,
        textAlign: 'left'
    }

    return (
        <footer style={container}>
            <Grid container spacing={1} justify="center" style={{flexGrow: 1}}>
                {!isApp && 
                    <Grid item sm={4} xs={12}>
                        <Contacts />
                    </Grid>}
                <Grid item sm={8} xs={12}>
                    <Discalaimer isApp={isApp} />
                </Grid>
            </Grid>
        </footer>
    )
}


const Contacts = () => {
    return (
        <ul style={{fontSize: 14, margin: 0}}>
            <li><Link style={link} to='/'>{translate('footer.homeLink')}</Link></li>
            <li><Link style={link} to='/characterManager'>{translate('footer.characterManager')}</Link></li>
            <li><Link style={link} to='/about'>{translate('footer.aboutLink')}</Link></li>
            <li><Link style={link} to='/guide'>{translate('footer.guideLink')}</Link></li>
            <li><Link style={link} to='/contact'>{translate('footer.contactLink')}</Link></li>
            <li><Link style={link} to='/privacy'>{translate('footer.privacyLink')}</Link></li>
        </ul>
    )
}


const Discalaimer = ({isApp}) => {
    return (
        <p style={{fontSize: 10}}>
            WIZARDS OF THE COAST, DUNGEONS {'&'} DRAGONS, AND THEIR LOGOS ARE TRADEMARKS OF WIZARDS OF THE COAST LLC IN THE UNITED STATES AND OTHER COUNTRIES. © 2016 WIZARDS. ALL RIGHTS RESERVED. 
            <br />
            DNDSPELLSLIST.COM IS NOT AFFILIATED WITH, ENDORSED, SPONSORED, OR SPECIFICALLY APPROVED BY WIZARDS OF THE COAST LLC. 
            DNDSPELLSLIST.COM MAY USE THE TRADEMARKS AND OTHER INTELLECTUAL PROPERTY OF WIZARDS OF THE COAST LLC, WHICH IS PERMITTED UNDER 
            {!isApp ? <a href="http://dnd.wizards.com/articles/features/fan-site-kit" style={{color: '#666'}}> WIZARDS' FAN SITE POLICY. </a> : <React.Fragment> WIZARDS' FAN SITE POLICY.</React.Fragment>}
            FOR EXAMPLE, DUNGEONS {'&'} DRAGONS® IS A TRADEMARK[S] OF WIZARDS OF THE COAST. 
            FOR MORE INFORMATION ABOUT WIZARDS OF THE COAST OR ANY OF WIZARDS' TRADEMARKS OR OTHER INTELLECTUAL PROPERTY, 
            PLEASE VISIT THEIR WEBSITE AT (
                {!isApp ? <a href="http://www.wizards.com" style={{color: '#666'}}>WWW.WIZARDS.COM</a> : <React.Fragment>WWW.WIZARDS.COM</React.Fragment>}
            ).
        </p>
    )
}


const link = {
    color: '#ccc'
}

export default SiteFooter
