import React from 'react'

import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from '../../helpers/JsFunctions'

import { SwipeableDrawer, Dialog, DialogTitle, IconButton, Divider } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { colors } from '../../helpers/Theme'

//Apre una Dialog se il sito è desktop o una SwipableDrawer se il sito è mobile
function ResponsiveDialog(props) {
    const { children, open, setOpen, title } = props;
    const isMobile = useMediaQuery({query: isMobileQuery});
    
    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    if(isMobile) {
        return (
            <SwipeableDrawer open={open} onClose={onClose} onOpen={onOpen} anchor="right" SlideProps={{style: {width: '100%'}}}>
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center', backgroundColor: colors.lightGray}}>
                    <IconButton onClick={onClose}>
                        <ChevronRightIcon />
                    </IconButton>
                </div>
                <Divider />
                {children}
            </SwipeableDrawer>
        )
    } else {
        return (
            <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth={true}>
                <DialogTitle>{title}</DialogTitle>
                {children}
            </Dialog>
        )
    }
}

export default ResponsiveDialog
