import React from 'react'
import { Card, CardHeader, CardContent, Collapse, IconButton, Typography, Avatar, CardActions, Button, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from '../../helpers/JsFunctions'

import { colors } from '../../helpers/Theme'

const SpellbookCard = ({spell, cardClicked}) => {
    const onClick = () => cardClicked(spell);
    const { name, level, school, description, atHigherLevels } = spell;
    return (
        <Card className="spell-card">
            <CardHeader 
                avatar={<Avatar style={{backgroundColor: colors.gold}}>{level}</Avatar>}
                title={<Typography variant="h6" color="secondary">{name}</Typography>}
                subheader={school}
                style={reducedPadding}
                disableTypography={true}/>
            <SpellCardContent onClick={onClick} description={description} atHigherLevels={atHigherLevels} />
        </Card>
    )
}


const SpellCardContent = ({onClick, description, atHigherLevels}) => {
    const [expanded, setExpanded] = React.useState(false);
    const isMobile = useMediaQuery({query: isMobileQuery});

    const expandClick = () => setExpanded(!expanded);

    if(isMobile) {
        return (
            <CardActions style={reducedPadding}>
                <Button size="small" color="secondary" onClick={onClick}>More info</Button>
            </CardActions>
        )
    } else {
        return (
            <React.Fragment>
            <CardActions style={reducedPadding}>
                <IconButton onClick={expandClick} style={{marginLeft: 'auto'}}>
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent style={reducedPadding}>
                    <Typography paragraph style={spellDesc}>
                        {description}
                    </Typography>
                    {atHigherLevels !== '' &&
                    <React.Fragment>
                        <Divider style={{height: 2, backgroundColor: colors.yellow}} />
                        <Typography paragraph style={spellDesc}>
                            {atHigherLevels}
                        </Typography>
                    </React.Fragment>}
                </CardContent>
            </Collapse>
            </React.Fragment>
        )
    }
}



const reducedPadding = {
    padding: 8
}

const spellDesc = {
    textAlign: 'left',
    fontSize: 14
}

export default SpellbookCard
