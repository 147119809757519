import React from 'react'
import { List } from '@material-ui/core'

import SpellListItem from './SpellListItem'

const SpellList = ({character, cards, characterSpellsChanged, type}) => {
    return (
        <List dense={true} style={{width: '100%', height: '100%', maxHeight: '100%', overflowY: 'scroll'}}>
            {cards.map((x, i) => {
                return (
                    <SpellListItem 
                        key={i + '_' + x.name}
                        character={character}
                        spell={x}
                        type={type}
                        characterSpellsChanged={characterSpellsChanged} />
                )
            })}
        </List>
    )
}

export default SpellList
