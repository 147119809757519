import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Button, Paper } from '@material-ui/core';

import SpellbookCard from './SpellbookCard';
import { groupBy } from '../../helpers/JsFunctions'
import { translate } from '../multiLanguage/Translator'


const SpellbookLink = React.forwardRef((props, ref) => (
    <Link innerRef={ref} to="/spellbook" target="_blank" {...props} />
));

export class Spellbook extends Component {
    state = {
        spells: [],
        groupedSpells: {}
    }

    changeSpells = (spells) => {
        //quando nuove spells vengono selezionate, tutte le spell selezionate vengono passate ogni volta
        const grouped = groupBy('level')(spells);
        this.setState({spells: spells, groupedSpells: grouped});

        localStorage.setItem('spellbook', JSON.stringify(grouped));
    }

    componentDidMount() {
        this.props.selectionChanged(this.changeSpells);
    }

    render() {
        if(this.state.spells.length === 0) {
            return (
                <div className="spellbook-container">
                    <Paper className="spells-paper">
                        <p style={{color: "gray"}}>{translate('spellbook.instruction')}</p>
                        <div className="button-container">
                        <Button disabled component={SpellbookLink} variant="contained" color={"secondary"} className="export-button">{translate('spellbook.buildSpellbook')}</Button>
                        </div>
                    </Paper>
                </div>
            )
        }
        else return (
            <div className="spellbook-container">
                <Paper className="spells-paper">
                    <div className="button-container">
                        <Button component={SpellbookLink} variant="contained" color={"secondary"} className="export-button">{translate('spellbook.buildSpellbook')}</Button>
                    </div>
                    <Grid container justify="center" spacing={2} >
                        {Object.keys(this.state.groupedSpells).map(itm => (
                                this.state.groupedSpells[itm].map(s => (
                                    <Grid key={s.name} item>
                                        <SpellbookCard spell={s} cardClicked={this.props.cardClicked} />
                                    </Grid>
                                ))
                        ))}
                    </Grid>
                </Paper>
            </div>
        )
    }
}

export default Spellbook
