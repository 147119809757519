import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import SiteHeader from './SiteHeader'
import SiteFooter from './SiteFooter'
import DictionaryInterface from './DictionaryInterface'
import Spellbook from '../spellbook/Spellbook'
import SpellbookInstructions from '../spellbook/SpellbookInstructions'

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { colors } from '../../helpers/Theme'
import dispatcher, { protocols } from '../../communication/Dispatcher'

const theme = createMuiTheme({
    palette: {
        secondary: { main: colors.red }
    }
});

export class AppContent extends Component {
    state = {
        spellbookVisible: false
    }

    spellbookToggled = (isVisible) => this.setState({spellbookVisible: isVisible});
    spellsChanged = (spells) => this.changeSpells(spells);

    componentDidMount() {
        dispatcher.sendMessage({
            showBanner: true
        }, protocols.Ads);
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Helmet>
                    <title>DnD Spells List | Spell index, spellbook, spell cards for Dungeons & Dragons</title>
                    <meta name="description" content="Dungeons and Dragons (D&D, DnD) Fifth Edition (5e) spells. A list of all the spells, spell index, create spellbooks, print them as cards or as a list" />
                </Helmet>
                <div className="app-content">
                    <SiteHeader 
                        changeLocale={this.localeChanged} />
                    <DictionaryInterface 
                        toggleSpellbook={this.spellbookToggled} 
                        changeSpells={this.spellsChanged} 
                        cardClicked={s => this.selectCard = s} />
                </div>
                {this.state.spellbookVisible ? 
                    <Spellbook 
                        selectionChanged={s => this.changeSpells = s} 
                        cardClicked={(spell) => this.selectCard(spell)} /> : 
                    <SpellbookInstructions />}
                <SiteFooter />
            </ThemeProvider>
        )
    }
}

export default AppContent
