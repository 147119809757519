import React from 'react'
import { Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

export default function ProgressButton({icon, text, variant, onClick, isSuccess, stop, isError, resetIn, style}) {
    const [isLoading, setLoading] = React.useState(false);

    const handleClick = () => {
        setLoading(true);
        onClick();
    }

    const buttonStyle = isSuccess ? buttonSuccess : {};

    return (
        <div style={{...buttonWrapper, ...style}}>
            <Button
                onClick={handleClick}
                variant={variant}
                color="secondary" 
                disabled={isLoading}
                startIcon={icon}
                size="small"
                style={buttonStyle}>
                    {text}
            </Button>
            {isLoading && <CircularProgress size={24} style={buttonProgress} />}
        </div>
    )
}

const buttonWrapper = {
    position: 'relative'
}
const buttonProgress = {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
}
const buttonSuccess = {
    backgroundColor: green[500]
}