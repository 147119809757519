import React from 'react'
import { FormControl, Select, MenuItem, InputLabel, Checkbox, ListItemText, Input } from '@material-ui/core'

//label: il testo che viene mostrato nella selezione; id: l'id html dell'elemento; options: tutti i vari valori che possono essere scelti
//filterType: funzione che viene chiamata quando viene scelto un elemento (sia aggiunto che tolto)
//selected: la lista di tutte le opzioni selezionate
const MultiSelect = ({label, id, options, filterType, selected}) => {
    const onChange = (e) => {
        let val = e.target.value;
        filterType(val);
    }

    return (
        <FormControl style={{minWidth: 250, height: 44, margin: '2px 10px'}}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <Select
                multiple
                value={selected}
                onChange={onChange}
                input={<Input id={id} />}
                renderValue={s => s.join(', ')}
                >
                {options.map(val => (
                    <MenuItem key={val} value={val}>
                    <Checkbox checked={selected.indexOf(val) > -1} />
                    <ListItemText primary={val} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default MultiSelect;