import React, { Component } from 'react'
import { RadioGroup, Radio, FormControl, FormControlLabel, FormLabel } from '@material-ui/core'
import { spellbookType } from '../../../helpers/JsFunctions'
// import CardCustomizer from './CardCustomizer'
// import ListCustomizer from './ListCustomizer'

export class ExportCustomizer extends Component {
    state = {
        spellbookType: spellbookType.card
    }
    
    spellbookTypeChanged = (e) => {
        this.props.spellbookTypeChanged(e.target.value);
        this.setState({spellbookType: e.target.value});
    }

    render() {
        return (
            <div style={{marginTop: 8}}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Spellbook type</FormLabel>
                    <RadioGroup value={this.state.spellbookType} onChange={this.spellbookTypeChanged} row>
                        <FormControlLabel value={spellbookType.card} control={<Radio />} label="Cards"/>
                        <FormControlLabel value={spellbookType.list} control={<Radio />} label="List"/>
                    </RadioGroup>
                </FormControl>
                {/* {this.state.spellbookType === spellbookType.card ? 
                    <CardCustomizer updateDimensions={this.props.updateDimensions} /> : <ListCustomizer />} */}
                {this.state.spellbookType === spellbookType.card &&
                    <p style={{fontSize: 12, color:'gray'}}>The spell card dimensions are the same as Magic or Pokémon cards. (2.4 x 3.4 inches | 6.3 x 8.8 cms)</p>}
            </div>
        )
    }
}

export default ExportCustomizer
