import React from 'react'
import counterpart from 'counterpart'
import { FormControl, Select, MenuItem } from '@material-ui/core'
import { colors } from '../../helpers/Theme'
import LocaleProvider from './LocaleProvider'

class LocaleSwitcher extends React.Component {
    state = {
        language: counterpart.getLocale()
    }

    handleChange = (e) => {
        let val = e.target.value;
        this.change(val);
    }

    change = (lang) => {
        counterpart.setLocale(lang);
        this.setState({language: lang});
        LocaleProvider.instance.localeChanged(lang);
        localStorage.setItem('locale', lang);
    }
    
    componentDidMount() {
        let locale = localStorage.getItem('locale');
        if(locale !== null && locale !== undefined && locale !== '') {
            setTimeout(() => this.change(locale), 500);     //devo farlo dopo mezzo secondo almeno dò tempo ai componenti di fare il rendering e di registrarsii all'evento
        }
    }

    render() {
        return (
            <FormControl>
                <Select
                    value={this.state.language}
                    onChange={this.handleChange}
                    inputProps={{ name: 'lang', id: 'lang', }}
                    style={{color: colors.gray}}
                >
                <MenuItem value={'en'}>English</MenuItem>
                <MenuItem value={'it'}>Italiano</MenuItem>
                </Select>
            </FormControl>
        );
    }
}

export default LocaleSwitcher