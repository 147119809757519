import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import MaterialTable from "material-table";

import { getSpells } from '../../data/SpellsData'
import SpellsTableToolbar from './SpellsTableToolbar'
import { getStringTranslation } from '../multiLanguage/Translator'
import LocaleProvider from '../multiLanguage/LocaleProvider'
import userData from '../../data/UserData'

export class SpellsTable extends Component {
    state = {
        spellList: getSpells(),
        filteredClasses: [],
        filteredDamageTypes: [],
        filteredBooks: [],
        rows: getSpells(),
        isSelection: false,
        isOnlyFavourites: false
    }
    
    schools = {
        abjuration: getStringTranslation('spell.school.abjuration'),
        conjuration: getStringTranslation('spell.school.conjuration'),
        divination: getStringTranslation('spell.school.divination'),
        enchantment: getStringTranslation('spell.school.enchantment'),
        evocation: getStringTranslation('spell.school.evocation'),
        illusion: getStringTranslation('spell.school.illusion'),
        necromancy: getStringTranslation('spell.school.necromancy'),
        transmutation: getStringTranslation('spell.school.transmutation')
    }


    renderBoolean = (rowData) => {
        let val = rowData === true ? getStringTranslation('text.yes') : getStringTranslation('text.no')
        return <React.Fragment>{val}</React.Fragment>
    }

    classSelected = (name) => {
        let filters = [...this.state.filteredClasses];
        let index = filters.indexOf(name);
        if(index === -1) {
            filters.push(name);
        } else {
            filters.splice(index, 1);
        }
        this.setState({filteredClasses: filters});

        const { spellList, filteredDamageTypes, filteredBooks } = this.state;
        
        const filtered = this.applyFilters(spellList, filteredDamageTypes, filters, filteredBooks);
        this.setState({rows: filtered});
    }
    filterByClass = (filters, rows) => {
        if(filters.length === 0) return rows;
        rows = rows.filter(itm => {
            for(let i = 0; i < filters.length; i++) {
                if(itm.availableClasses.indexOf(filters[i]) > -1) return true;
            }
            return false;
        })
        return rows;
    }

    damageTypeSelected = (type) => {
        let filters = [...type];
        this.setState({filteredDamageTypes: filters});

        const { spellList, filteredClasses, filteredBooks } = this.state;

        const filtered = this.applyFilters(spellList, filters, filteredClasses, filteredBooks);
        this.setState({rows: filtered});
    }
    filterByDamageType = (filters, rows) => {
        if(filters.length === 0) return rows;
        rows = rows.filter(itm => {
            for(let i = 0; i < filters.length; i++) {
                let filterVal = filters[i].toLowerCase();
                if(itm.damageTypes.indexOf(filterVal) > -1) return true;
            }
            return false;
        })
        return rows;
    }
    
    rowClicked = (e, data) => {
        //this.props.history.push('/spells/' + getUrlFriendlyName(data.name));
        this.props.spellSelected(data);
    }

    toggleSelection = () => {
        let isVisible = !this.state.isSelection;
        this.setState({isSelection: isVisible});
        this.props.toggleSpellbook(isVisible);
    }

    selectionChanged = (rows) => {
        this.props.selectionChanged(rows);
    }

    showFavourites = () => {
        let isFavourite = !this.state.isOnlyFavourites;
        let spells = isFavourite ? userData.getFavouriteSpells() : [...this.state.spellList];
        spells = this.filterByClass(this.state.filteredClasses, spells);
        spells = this.filterByDamageType(this.state.filteredDamageTypes, spells);

        this.setState({rows: spells, isOnlyFavourites: isFavourite, filteredClasses: [], filteredDamageTypes: []});
    }

    bookSelected = (book) => {
        let books = [...book];
        this.setState({filteredBooks: books});

        const { spellList, filteredDamageTypes, filteredClasses  } = this.state;
        
        const filtered = this.applyFilters(spellList, filteredDamageTypes, filteredClasses, books);
        this.setState({rows: filtered});
    }
    filterByBook = (filters, rows) => {
        if(filters.length === 0) return rows;
        rows = rows.filter(itm => {
            for(let i = 0; i < filters.length; i++) {
                let filterVal = filters[i];
                if(itm.bookSource === filterVal)
                    return true;
            }
            return false;
        })
        return rows;
    }

    applyFilters = (rows, damageFilter, classFilter, bookFilter) => {
        let filtered = this.filterByDamageType(damageFilter, rows);
        filtered = this.filterByClass(classFilter, filtered);
        filtered = this.filterByBook(bookFilter, filtered);
        return filtered;
    }

    changeLocale = () => {
        const list = getSpells();
        this.setState({spellList: list, rows: list, filteredClasses: [], filteredDamageTypes: []});

        this.schools = {
            abjuration: getStringTranslation('spell.school.abjuration'),
            conjuration: getStringTranslation('spell.school.conjuration'),
            divination: getStringTranslation('spell.school.divination'),
            enchantment: getStringTranslation('spell.school.enchantment'),
            evocation: getStringTranslation('spell.school.evocation'),
            illusion: getStringTranslation('spell.school.illusion'),
            necromancy: getStringTranslation('spell.school.necromancy'),
            transmutation: getStringTranslation('spell.school.transmutation')
        }
    }

    getSchoolsLookup = () => {
        let res = {};
        res[this.schools.abjuration] = this.schools.abjuration;
        res[this.schools.conjuration] = this.schools.conjuration;
        res[this.schools.divination] = this.schools.divination;
        res[this.schools.enchantment] = this.schools.enchantment;
        res[this.schools.evocation] = this.schools.evocation;
        res[this.schools.illusion] = this.schools.illusion;
        res[this.schools.necromancy] = this.schools.necromancy;
        res[this.schools.transmutation] = this.schools.transmutation;
        return res;
    }

    componentDidMount() {
        this.props.classSelected(this.classSelected);
        LocaleProvider.instance.subscribe('SpellsTable', this.changeLocale);
    }

    render() {
        const boolLookup = { true: getStringTranslation('text.yes'), false: getStringTranslation('text.no') };
        return (
            <Grid item xs={12} style={{height: '100%'}}>
                <MaterialTable 
                    columns={[
                        { title: 'Name', field: 'name', filtering: false, searchable: true, defaultSort: 'asc' },
                        { title: 'Level', field: 'level', searchable: false, cellStyle: {width: 60}, lookup: { '0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9} },
                        { title: 'School', field: 'school', searchable: false, lookup: this.getSchoolsLookup() },
                        { title: 'Ritual', field: 'ritual', searchable: false, render: rowData => this.renderBoolean(rowData.ritual), lookup: boolLookup, cellStyle:{ width: 60 } },
                        { title: 'Concentration', field: 'concentration', searchable: false, render: rowData => this.renderBoolean(rowData.concentration), lookup: boolLookup, cellStyle:{ width: 110 } },
                    ]}
                    data={this.state.rows}
                    title={'Spell List'}
                    options={{
                        padding: 'dense',
                        filtering: true,
                        sorting: true,
                        paging: true,
                        pageSize: 50,
                        pageSizeOptions: [50, 100, 200],
                        draggable: false,
                        headerStyle: {
                            fontWeight: 'bold',
                            fontSize: 16
                        },
                        selection: this.state.isSelection
                    }}
                    onRowClick={this.rowClicked}
                    style={{height: "100%", overflowY: "scroll"}}
                    components={{
                        Toolbar: props => (
                            <SpellsTableToolbar 
                                toolbarProps={props} 
                                toggleSelection={this.toggleSelection} 
                                filterType={this.damageTypeSelected} 
                                filteredDamageTypes={this.state.filteredDamageTypes}
                                showFavourites={this.showFavourites} 
                                isSelection={this.state.isSelection}
                                isOnlyFavourites={this.state.isOnlyFavourites}
                                filterBook={this.bookSelected}
                                selectedBook={this.state.filteredBooks} />
                        )
                    }}
                    onSelectionChange={(rows) => this.selectionChanged(rows)} />
            </Grid>
        )
    }
}

export default SpellsTable
