import React from 'react'
import { Helmet } from 'react-helmet'
import SiteHeader from '../layout/SiteHeader'
import SiteFooter from '../layout/SiteFooter'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { colors } from '../../helpers/Theme'

import CharacterManager from './CharacterManager'

const theme = createMuiTheme({
    palette: { secondary: { main: colors.red } }
})

const CharacterManagerIndex = () => {
    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>DnD Spells List | Character Manager for Dungeons & Dragons</title>
                <meta name="description" content="Character and Spellcaster manager for Dungeons and Dragons (D&D, DnD) Fifth Edition (5e) spells. Manage your spellbook, spellslots and all of your magic." />
            </Helmet>
            <div className="app-container">
                <div className="app-content">
                    <SiteHeader />
                    <div style={{height: 'calc(100vh - 56px)'}}>
                        <CharacterManager />
                    </div>
                </div>
                <SiteFooter />
            </div>
        </ThemeProvider>
    )
}

export default CharacterManagerIndex;