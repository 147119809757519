import React, { Component } from 'react'
import { Paper, Grid, Divider } from '@material-ui/core';
import ExportSpellbookCard from './ExportSpellbookCard'

const MAX_LENGTH = 630;

export class ExportCardRender extends Component {
    componentDidMount() {
        this.props.dimensionUpdated(this.updateDimensions);
    }

    render() {
        const { groupedSpells } = this.props;
        let counter = -1;
        return (
            <div className="export-spellbook-container" ref={r => this.props.setToPrint(r)}>
                <Paper className="spells-paper">
                    <Grid container justify="center" spacing={1} >
                        {Object.keys(groupedSpells).map(itm => (
                            groupedSpells[itm].map((spell, index) => {
                                const charLength = (spell.description + spell.atHigherLevels).length;
                                //devo controllare se il testo è troppo grande per entrare in una sola card, se lo è allora devo crearne due, splittando il testo, che separano il contenuto
                                if(charLength <= MAX_LENGTH) {
                                    counter++;
                                    return (
                                        <React.Fragment>
                                            {counter % 9 === 0 && counter !== 0 &&
                                                <Divider key={counter + '_div'} className="export-card-page-break" />}
                                            <Grid key={counter} item>
                                                <ExportSpellbookCard spell={spell} updateDimensions={u => this.updateDimensions = u} />
                                            </Grid>
                                        </React.Fragment>)
                                } else {
                                    //devo vedere quanto è effettivamente lungo il testo per sapere quante spellcard devo fare (2, 3, 4 ...)
                                    let num = Math.ceil(charLength / (MAX_LENGTH - 80));
                                    let lastIndex = 0;
                                    return Array(num).fill(0).map((itm, i) => {
                                        counter++;
                                        const newSpell = {...spell};
                                        let chunkLenght = MAX_LENGTH;
                                        if(i === 0) {
                                            if(newSpell.materialComponents !== null && newSpell.materialComponents !== '') {
                                                newSpell.description = newSpell.materialComponents + '.\n' + newSpell.description;
                                                chunkLenght -= newSpell.materialComponents.length;
                                            }
                                        }
                                        // let chunkText = newSpell.description.substring((chuckLenght * i), chuckLenght * (i + 1));
                                        let length = chunkLenght * (i + 1) - lastIndex;
                                        if(length > MAX_LENGTH) length = MAX_LENGTH;
                                        let chunkText = newSpell.description.substring(lastIndex, lastIndex + length);
                                        let pointIndex = chunkText.lastIndexOf('.') + 1;
                                        if(i !== num - 1) {
                                            newSpell.atHigherLevels = '';
                                            newSpell.description = chunkText.substring(0, pointIndex);
                                        } else {
                                            //è l'ultima spell
                                            newSpell.description = newSpell.description.substring(lastIndex, newSpell.description.length);
                                            //potrebbe capitare che essendo l'ultima card e prendendo tutto il testo rimanente, 
                                            //l'accumularsi di errori fa si che la descrizione è troppo grande, la splitto quindi in due
                                            if(newSpell.description.length > MAX_LENGTH) {
                                                const newSpell1 = {...newSpell};
                                                newSpell1.description = chunkText.substring(0, pointIndex);
                                                newSpell1.atHigherLevels = '';

                                                const newSpell2 = {...newSpell};
                                                newSpell2.description = newSpell2.description.substring(pointIndex, newSpell2.description.length);

                                                counter += 2;

                                                return (
                                                    <React.Fragment>
                                                        {(counter - 1) % 9 === 0 && (counter - 1) !== 0 &&
                                                            <Divider key={(counter - 1) + '_div'} className="export-card-page-break" />}
                                                        <Grid key={counter - 1} item>
                                                            <ExportSpellbookCard spell={newSpell1} updateDimensions={u => this.updateDimensions = u} />
                                                        </Grid>
                                                        {counter % 9 === 0 && counter !== 0 &&
                                                            <Divider key={counter + '_div'} className="export-card-page-break" />}
                                                        <Grid key={counter} item>
                                                            <ExportSpellbookCard spell={newSpell2} updateDimensions={u => this.updateDimensions = u} />
                                                        </Grid>
                                                    </React.Fragment>
                                                );
                                            }
                                        }
                                        
                                        lastIndex += pointIndex - ((i === 0 && newSpell.materialComponents !== null && newSpell.materialComponents !== '') ? newSpell.materialComponents.length + 1 : 0);
                                        return (
                                            <React.Fragment>
                                                {counter % 9 === 0 && counter !== 0 &&
                                                    <Divider key={counter + '_div'} className="export-card-page-break" />}
                                                <Grid key={counter} item>
                                                    <ExportSpellbookCard spell={newSpell} updateDimensions={u => this.updateDimensions = u} />
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            })
                        ))}
                    </Grid>
                </Paper>
            </div>
        )
    }
}

export default ExportCardRender
