import React, { Component } from 'react'
import { CardHeader, Avatar, Grid, Typography, Divider } from '@material-ui/core';
import { colors } from '../../../helpers/Theme'

export class ExportSpellbookListItem extends Component {
    render() {
        const { spell } = this.props;
        const { 
            name, level, school, 
            castingTime, ritual, range, 
            components, duration, description, 
            atHigherLevels 
        } = spell;
        const comps = components.join(', ');
        const subHeader = school + (ritual ? ' (Ritual)' : '');
        return (
            <div className="list-item-container">
                <CardHeader
                    avatar={<Avatar className="export-card-avatar">{level}</Avatar>}
                    title={name}
                    titleTypographyProps={{className: 'list-item-title'}}
                    subheader={subHeader}
                    subheaderTypographyProps={{className: 'list-item-subheader'}}/>
                <Grid container direction="row" justify="space-between" className="spell-table">
                    <Grid item xs={12} sm={6}>Casting Time: <strong>{castingTime}</strong></Grid>
                    <Grid item xs={12} sm={6}>Range: <strong>{range}</strong></Grid>
                    <Grid item xs={12} sm={6}>Duration: <strong>{duration}</strong></Grid>
                    <Grid item xs={12} sm={6}>Components: <strong>{comps}</strong></Grid>
                </Grid>
                <Typography className="list-item-description">
                    {description}
                </Typography>
                {atHigherLevels !== '' &&
                    <React.Fragment>
                        <Typography className="list-item-higher-title">
                            At Higher Levels
                        </Typography>
                        <Divider style={{height: 2, backgroundColor: colors.yellow}} />
                        <Typography className="list-item-higher-content">
                            {atHigherLevels}
                        </Typography>
                    </React.Fragment>}
            </div>
        )
    }
}

export default ExportSpellbookListItem
