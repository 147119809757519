import React from 'react'
import { Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ClassSelector from './ClassSelector'
import { translate, getStringTranslation, getLocale } from '../multiLanguage/Translator'
import LocaleProvider from '../multiLanguage/LocaleProvider'
import { isMobileQuery } from '../../helpers/JsFunctions'
import { colors } from '../../helpers/Theme'
import { allClasses } from '../../data/GameData'

const ClassToolbar = (props) => {
    const [lang, setLang] = React.useState(getLocale());
    LocaleProvider.instance.subscribe('ClassToolbar', (lang) => {
        setLang(lang);
    });

    const isMobile = useMediaQuery({query: isMobileQuery});
    const container = {
        padding: isMobile === false ? 10 : 0
    }

    const classSelected = (name) => {
        props.classSelected(name);
    }

    const toolbar = (
        <Grid container spacing={1} justify="center" style={container}>
            {allClasses.map((itm, index) => {
                const translationKey = 'classes.' + itm.toLowerCase();
                return (
                    <Grid key={index} item xs={6} sm={2}>
                        <ClassSelector logo={itm} text={translate(translationKey)} rawText={getStringTranslation(translationKey)} onClick={classSelected} isClickable={true} />
                    </Grid>                    
                );
            })}
        </Grid>
    );

    if(isMobile) {
        return (
            <ExpansionPanel style={{backgroundColor: colors.lightGray}}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="class-selection-panel">
                    <Typography>{translate('classes.expandLabel')}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {toolbar}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    } else {
        return toolbar;
    }
}

export default ClassToolbar
