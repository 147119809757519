import React, { Component } from 'react'
import { Chip } from '@material-ui/core'

import LogoImmage from '../controls/LogoImmage';
import LocaleProvider from '../multiLanguage/LocaleProvider'
import { colors } from '../../helpers/Theme'

import barbarianLogo from '../../images/classesLogo/barbarian_logo.png'
import bardLogo from '../../images/classesLogo/bard_logo.png'
import clericLogo from '../../images/classesLogo/cleric_logo.png'
import druidLogo from '../../images/classesLogo/druid_logo.png'
import fighterLogo from '../../images/classesLogo/fighter_logo.png'
import monkLogo from '../../images/classesLogo/monk_logo.png'
import paladinLogo from '../../images/classesLogo/paladin_logo.png'
import rangerLogo from '../../images/classesLogo/ranger_logo.png'
import rogueLogo from '../../images/classesLogo/rogue_logo.png'
import sorcererLogo from '../../images/classesLogo/sorcerer_logo.png'
import warlockLogo from '../../images/classesLogo/warlock_logo.png'
import wizardLogo from '../../images/classesLogo/wizard_logo.png'

export class ClassSelector extends Component {
    state = {
        isSelected: false
    }

    getLogo = () => {
        switch(this.props.logo) {
            case 'Barbarian':
                return barbarianLogo;
            case 'Bard':
                return bardLogo;
            case 'Cleric':
                return clericLogo;
            case 'Druid':
                return druidLogo;
            case 'Fighter':
                return fighterLogo;
            case 'Monk':
                return monkLogo;
            case 'Paladin':
                return paladinLogo;
            case 'Ranger':
                return rangerLogo;
            case 'Rogue':
                return rogueLogo;
            case 'Sorcerer':
                return sorcererLogo;
            case 'Warlock':
                return warlockLogo;
            case 'Wizard':
                return wizardLogo;
            default: return 'none';
        }
    }

    chipSelected = () => {
        if(this.props.isClickable) {
            var newState = !this.state.isSelected;
            this.setState({isSelected: newState});
            this.props.onClick(this.props.rawText);
        }
    }

    componentDidMount() {
        LocaleProvider.instance.subscribe('ClassSelector' + this.props.logo, () => {
            this.setState({isSelected: false});
        });
    }

    componentWillUnmount() {
        LocaleProvider.instance.unSubscribe();
    }

    render() {
        const { isClickable, text } = this.props;
        const logo = this.getLogo();
        const selectedOrDefaultStyle = this.state.isSelected ? selectedStyle : { /* stile default del tema */ };

        return (
            <Chip 
                label={text} 
                color='default' 
                clickable={isClickable} 
                onClick={this.chipSelected} 
                avatar={<LogoImmage logo={logo} />} 
                style={{...avatar, ...selectedOrDefaultStyle}} />
        )
    }
}

const avatar = {
    objectFit: 'contain',
    fontSize: 18,
    fontWeight: 'bold'
};

const selectedStyle = {
    backgroundColor: colors.goldYellow,
    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)'
};

export default ClassSelector
