import React, { Component } from 'react'
import { Card, CardHeader, Avatar, CardContent, Typography, CardActions, Grid } from '@material-ui/core'

export class ExportSpellbookCard extends Component {
    state = {
        dimensions: { width: 232, height: 322 },    //espressa in pixel
    }

    updateDimensions = (width, height) => {
        const dim = {...this.state.dimensions};
        //aggiungo 2 per il bordo di destra e di sinistra
        dim.width = width + 2;
        dim.height = height + 2;
        this.setState({dimensions: dim});
    }

    componentDidMount() {
        this.props.updateDimensions(this.updateDimensions);
    }

    render() {
        const { dimensions } = this.state;
        const cardStyle = {
            width: dimensions.width, 
            height: dimensions.height
        };

        const { spell } = this.props;
        const { 
            name, level, school, 
            castingTime, ritual, concentration,
            range, components, duration, 
            description, atHigherLevels, materialComponents,
        } = spell;
        const durationLabel = duration.replace('Concentration, ', '').replace('minute', 'min.').replace('minutes', 'min.');
        const subHeader = school + (ritual ? ' (Ritual)' : '') + (concentration ? ' (Concen.)' : '');
        const comps = components.join(', ').replace('(', '').replace(')', '').replace(materialComponents, '');
        return (
            <Card className="export-spell-card" style={cardStyle}>
                <CardHeader 
                    avatar={<Avatar className="export-card-avatar">{level}</Avatar>}
                    title={name}
                    titleTypographyProps={{color:'secondary', className:'export-card-title'}}
                    subheader={subHeader}
                    subheaderTypographyProps={{className: 'export-card-subheader'}}
                    className="export-card-header" />
                <CardContent className="export-card-content" style={{flexGrow: 1}}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>Casting Time: <strong>{castingTime}</strong></Grid>
                        <Grid item xs={12} sm={6}>Range: <strong>{range}</strong></Grid>
                        <Grid item xs={12} sm={6}>Duration: <strong>{durationLabel}</strong></Grid>
                        <Grid item xs={12} sm={6}>Components: <strong>{comps}</strong></Grid>
                    </Grid>
                    <Typography className='export-card-description'>
                        {description}
                    </Typography>
                </CardContent>
                {atHigherLevels !== '' &&
                    <CardActions className="export-card-actions">
                        <div>
                            <Typography className="export-card-higher-title">At Higher Levels</Typography>
                            <Typography className="export-card-higher-text">{atHigherLevels}</Typography>
                        </div>
                    </CardActions>}
            </Card>
        )
    }
}

export default ExportSpellbookCard
