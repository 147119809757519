import React from 'react';
import { render } from 'react-snapshot';
import './index.css';
import App from './App';
import ExportSpellbook from './components/export/ExportSpellbook'
import About from './pages/About'
import Guide from './pages/Guide'
import Contact from './pages/Contact'
import Privacy from './pages/Privacy'
import CharacterManagerIndex from './components/characters/CharacterManagerIndex'

import './styles/App.css';
import './styles/SiteHeaderStyles.css'
import './styles/DictionaryInterfaceStyles.css'
import './styles/OverriddenStyles.css'
import './styles/SpellbookCardStyles.css'
import './styles/SpellbookStyles.css'
import './styles/SpellDetailStyles.css'
import './styles/ExportSpellbookCardStyles.css'
import './styles/CardCustomizerStyles.css'
import './styles/ExportSpellbookListItemStyles.css'
import './styles/GuideStyles.css'
import './styles/AboutStyles.css'
import './styles/SpellCarouselStyles.css'
import './styles/SpellCarouselCardStyles.css'

import { Route, BrowserRouter as Router } from 'react-router-dom'

const routing = (
    <Router>
        <Route exact path="/" component={App} />
        <Route path="/spellbook" component={ExportSpellbook} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/guide" component={Guide} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/characterManager" component={CharacterManagerIndex} />
        <Route path="/spells/:spellName?" component={App} />
        {/* <Route path="/test" component={Deck} /> */}
    </Router>
)

render(routing, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
