import React, { Component } from 'react'
import ExportSpellbookListItem from './ExportSpellbookListItem'
import { Paper, Divider } from '@material-ui/core'

const MAX_CHARS = 3300;     //il massimo che posso tenere in una pagina
const DEFAULT_CHARS = 700;  //il minimo che una spell occupa (considerando il titolo, i componenti ...)
const HIGHER_LEVELS_DEFAULT_CHARS = 200;

export class ExportListRenderer extends Component {
    getSpellCharCount = (spell) => {
        //vado a calcolare quanti char mi occuperà la spell all'interno della pagina
        let count = DEFAULT_CHARS;
        count += spell.description.length;
        if(spell.atHigherLevels.length > 0) {
            count += spell.atHigherLevels.length;
            count += HIGHER_LEVELS_DEFAULT_CHARS;   //se la spell può essere castata a livelli maggiori, allora occupa più spazio per questo conto due righe medie) in più, fra testo e riga
        }
        return count;
    }

    render() {
        const { groupedSpells } = this.props;
        let charCount = 0;
        return (
            <div className="export-spellbook-container" ref={r => this.props.setToPrint(r)}>
                <Paper className="spells-paper">
                    {Object.keys(groupedSpells).map(itm => (
                        groupedSpells[itm].map((spell, index) => {
                            const newChars = this.getSpellCharCount(spell);
                            if(charCount + newChars > MAX_CHARS) {
                                charCount = newChars;
                                return (
                                    <React.Fragment>
                                        <Divider key={index + '_div'} className="export-card-page-break" style={{display: 'none'}} />
                                        <ExportSpellbookListItem key={index} spell={spell} />
                                    </React.Fragment>
                                )
                            } else {
                                charCount += newChars;
                                return (
                                    <ExportSpellbookListItem key={index} spell={spell} />
                                );
                            }
                        })
                    ))}
                </Paper>
            </div>
        )
    }
}

export default ExportListRenderer
