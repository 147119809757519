import React from 'react'
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const variants = {
    success: 'success',
    warning: 'warning',
    error: 'error',
    info: 'info',
}

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const StatusMessage = ({show}) => {
    const classes = createStyles();
    const [open, setOpen] = React.useState(false);
    const [variant, setVariant] = React.useState('warning')
    const [message, setMessage] = React.useState('');
    const [vertical, setVertical] = React.useState('bottom');

    const handleOpen = (message, variant, verticalPosition) => {
        setMessage(message);
        setVariant(variant);
        if(verticalPosition !== undefined) setVertical(verticalPosition);
        setOpen(true);
    }
    const handleClose = (e, reason) => {
        if(reason === 'clickaway') return;
        setOpen(false);
    }

    const Icon = variantIcon[variant];

    show(handleOpen);

    return (
        <Snackbar
            anchorOrigin={{vertical: vertical, horizontal: 'left'}}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}>
                <SnackbarContent className={classes.margin + ' ' + classes[variant]}
                    aria-describedby="client-snackbar"
                    message={
                        <span className={classes.message}>
                            <Icon className={classes.icon + ' ' + classes.iconVariant} />
                            {message}
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon className={classes.icon} />
                        </IconButton>
                    ]}
                />
        </Snackbar>
    )
}

const createStyles = makeStyles(theme => ({
    icon: {
        fontSize: 20
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
}))

export default StatusMessage