import React from 'react'
import { Paper } from '@material-ui/core'
import { translate } from '../multiLanguage/Translator'

const SpellDetailNotFound = (props) => {
    return (
        <Paper style={{...paper, ...{color: "lightgray"}}}>{translate('spellDetail.placeholder')}</Paper>
    );
}

const paper = {
    padding: 20
}

export default SpellDetailNotFound;