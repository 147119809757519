import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from '../../../helpers/JsFunctions'

import CharacterSpellsTable from '../table/CharacterSpellsTable'
import CharacterSpellDetail from '../CharacterSpellDetail'
import dispatcher, { protocols } from '../../../communication/Dispatcher'

const AllSpells = ({character, spellSelected, spells, changeSelectedSpells, toggleSelection, showSelectedSpell}) => {
    const isMobile = useMediaQuery({query: isMobileQuery});

    dispatcher.sendMessage({
        showBanner: true
    }, protocols.Ads);

    if(isMobile) {
        return (
            <React.Fragment>
            <div style={{height: '100%'}}>
                <CharacterSpellsTable 
                    characterName={character.name}
                    spellSelected={spellSelected}
                    spellList={spells}
                    selectionChanged={changeSelectedSpells}
                    toggleSelection={toggleSelection}
                    character={character} />
                <CharacterSpellDetail spellSelected={showSelectedSpell} />
            </div>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
            <div style={{height: '100%', display: 'flex'}}>
                <div style={{flex: '0 1 50%'}}>
                    <CharacterSpellsTable 
                        characterName={character.name}
                        spellSelected={spellSelected}
                        spellList={spells}
                        selectionChanged={changeSelectedSpells}
                        toggleSelection={toggleSelection}
                        character={character} />
                </div>
                <div style={{flex: '0 1 50%'}}>
                    <CharacterSpellDetail spellSelected={showSelectedSpell} />
                </div>
            </div>
            </React.Fragment>
        )   
    }
}

export default AllSpells