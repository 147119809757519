import React from 'react';

import AppContent from './components/layout/AppContent';
import MobileAppSuggest from './components/controls/MobileAppSuggest';

function App(props) {
  return (
    <div className="app-container">
        <AppContent {...props} />
        <MobileAppSuggest />
    </div>
  );
}

export default App;
