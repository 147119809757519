import React, { Component } from 'react'

import { DialogContent, DialogActions, Button, Dialog, DialogTitle } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import ResponsiveDialog from '../../controls/ResponsiveDialog'
import CharacterClassSelection from '../CharacterClassSelection'
import { remove } from '../../../helpers/JsFunctions'
import userData from '../../../data/UserData';
import { translate, getStringTranslation } from '../../multiLanguage/Translator';

export class EditCharacterStats extends Component {
    state = {
        isOpen: false,
        character: null,
        dialogOpen: false
    }

    setOpen = (isOpen) => this.setState({isOpen: isOpen});

    showEditor = (character) => {
        this.setState({isOpen: true, character: character});
    }

    addClass = (classObj) => {
        //se già la ho, la aggiorno, altrimenti la aggiungo
        let { character } = this.state;
        let classes = remove(character.classes, { name: classObj.name }, 'name');
        classes = [...classes, classObj];
        userData.updateClasses(character.name, classes);
        this.setState({character: userData._getCharacter(character.name)});
    }
    removeClass = (className) => {
        let { character } = this.state;
        if(character.classes.length < 2) return;
        let classes = remove(character.classes, { name: className }, 'name');
        userData.updateClasses(character.name, classes);
        this.setState({character: userData._getCharacter(character.name)});
    }

    deleteCharacter = () => {
        this.setState({dialogOpen: true});
    }
    confirmDelete = () => {
        userData.deleteCharacter(this.state.character.name);
        this.props.refreshCharacters();
        this.setState({dialogOpen: false, isOpen: false});
    }

    componentDidMount() {
        this.props.showPage(this.showEditor);
    }

    render() {
        const { character, dialogOpen } = this.state;
        const name = character !== null ? character.name : '';
        return (
            <React.Fragment>
                <EditCharacterStatsUI 
                    character={character}
                    open={this.state.isOpen} 
                    setOpen={this.setOpen} 
                    addClass={this.addClass}
                    removeClass={this.removeClass}
                    deleteCharacter={this.deleteCharacter} />
                <Dialog open={dialogOpen} onClose={() => this.setState({dialogOpen: false})}>
                    <DialogTitle>{getStringTranslation('editCharacter.deleteCharacter')} <strong>{name}</strong> ?</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => this.setState({dialogOpen: false})}>No</Button>
                        <Button color="secondary" onClick={this.confirmDelete}>Yes</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

const EditCharacterStatsUI = ({open, setOpen, character, addClass, removeClass, deleteCharacter}) => {
    const classes = character !== null ? character.classes : [];

    return (
        <ResponsiveDialog open={open} setOpen={setOpen} title="Edit Character Stats">
            <DialogContent>
                {translate('editCharacter.editStats')}
                <CharacterClassSelection 
                    classes={classes} 
                    addClass={addClass} 
                    removeClass={removeClass} 
                    character={character} />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" startIcon={<DeleteIcon />} onClick={deleteCharacter}>Delete</Button>
            </DialogActions>
        </ResponsiveDialog>
    )
}

export default EditCharacterStats
