import dispatcher, { protocols } from '../communication/Dispatcher'
import { addOrUpdate, addIfNew, remove } from '../helpers/JsFunctions'
import { colors } from '../helpers/Theme';

let instance = null;
class UserData {
    _data = null;        //i dati dell'utente corrente
    isReady = false;    //se l'inizializzazione dei dati dell'utente è stata completata

    constructor() {
        if (!instance) {
            instance = this;
        }

        //chiedo a react-native se mi restituisce i dati dell'utente, se dopo 2 secondi non mi ha risposto, allora non sono in react-native, li prendo dal localStorage
        dispatcher.subscribe('UserData', this._messageReceived)
        setTimeout(() => {
            this.isReady = true;
            if(this._data === null) {
                let rawData = localStorage.getItem(protocols.UserData);
                if(rawData === '' || rawData === null || rawData === undefined) {
                    this._data = {
                        favouriteSpells: [],
                        characters: []
                    }
                } else {
                    this._data = JSON.parse(rawData);
                }
            }
        }, 1500);

        return instance;
    }

    //i dati dell'utente sono stati recuperati con successo
    //da chiamare sempre quando si vuole interagire con l'oggetto userData per essere sicuri che i dati dell'utente siano pronti per essere acceduti
    whenReady = (callback) => {
        if(this.isReady) {
            callback();
            return;
        }
        setTimeout(() => {
            this.whenReady(callback);
        }, 100);
    }


    favouriteSpell = (spell) => {
        if(this.isReady) {
            let index = this._data.favouriteSpells.map(itm => itm.name).indexOf(spell.name);
            if(index > -1)
                this._data.favouriteSpells.splice(index, 1);
            else
                this._data.favouriteSpells.push(spell);
            this._save();
        }
    }
    getFavouriteSpells = () => {
        if(this.isReady)
            return this._data.favouriteSpells;
        else
            return [];
    }
    isFavouriteSpell = (spell) => {
        if(this.isReady) {
            if(this._data.favouriteSpells.find(x => x.name === spell.name) !== undefined)
                return true;
        }
        return false;
    }



    getAllCharacters = () => {
        return this._data.characters;
    }

    //crea o aggiorna un personagggio
    setCharacter = (name, race, classes, image, description, preparedSpells, knownSpells) => {
        if(!this.isReady) return;
        const character = {
            name: name,
            race: race,
            classes: classes,
            image: image,
            description: description,
            preparedSpells: preparedSpells, 
            knownSpells: knownSpells
        };
        this._data.characters = addOrUpdate(this._data.characters, character, 'name');
        this._save();
    }

    
    getKnownSpells = (cName) => {
        let character = this._getCharacter(cName);
        return this.__sortSpells(character.knownSpells);
    }
    addKnownSpells = (cName, spells) => {
        let character = this._getCharacter(cName);
        spells.forEach(singleSpell => {
            character.knownSpells = addIfNew(character.knownSpells, singleSpell, 'name');
        });
        this._save();
    }
    removeKnownSpells = (cName, spells) => {
        let character = this._getCharacter(cName);
        const toRemove = spells.map(x => x.name);
        const known = [];
        for(let i = 0; i < character.knownSpells.length; i++) {
            if(toRemove.indexOf(character.knownSpells[i].name) < 0) {
                known.push(character.knownSpells[i]);
            }
        }
        character.knownSpells = known;
        this._save();
    }


    getPreparedSpells = (cName) => {
        let character = this._getCharacter(cName);
        return this.__sortSpells(character.preparedSpells);
    }
    isPrepared = (cName, spell) => {
        let character = this._getCharacter(cName);
        let hit = character.preparedSpells.find(x => x.name === spell.name);
        return hit !== undefined;
    }
    addPreparedSpells = (cName, spells) => {
        let character = this._getCharacter(cName);
        spells.forEach(singleSpell => {
            character.preparedSpells = addIfNew(character.preparedSpells, singleSpell, 'name');
        });
        this._save();
    }
    removePreparedSpells = (cName, spells) => {
        let character = this._getCharacter(cName);
        const toRemove = spells.map(x => x.name);
        const prepared = [];
        for(let i = 0; i < character.preparedSpells.length; i++) {
            if(toRemove.indexOf(character.preparedSpells[i].name) < 0) {
                prepared.push(character.preparedSpells[i]);
            }
        }
        character.preparedSpells = prepared;
        this._save();
    }


    updateClasses = (cName, classes) => {
        let character = this._getCharacter(cName);
        character.classes = classes;
        this._save();
    }

    deleteCharacter = (cName) => {
        this._data.characters = remove(this._data.characters, { name: cName }, 'name');
        this._save();
    }


    //utils
    getSpellClassColor = (character, spell) => {
        let available = spell.availableClasses;
        let color = colors.gold;
        available.forEach(possible => {
            let cClass = character.classes.find(x => x.name.toLowerCase() === possible.toLowerCase());
            if(cClass !== undefined)
                color = cClass.color;
        });
        return color;
    }
    __sortSpells = (spells) => {
        return spells.sort((a, b) => {
            if(a.level > b.level) return 1;
            else if(a.level < b.level) return -1;
            else {
                if(a.name > b.name) return 1;
                else return -1;
            }
        });
    }


    //ritorna i dati di un personaggio dalla lista di quelli salvati
    _getCharacter = (name) => {
        const character = this._data.characters.find(x => x.name === name);
        if(character === undefined) return null;
        else return character;
    }

    //è arrivato un messagio da react-native
    _messageReceived = (data, protocol) => {
        if(protocol === protocols.UserData) {
            //alert('message received ' + JSON.stringify(data))
            this._data = data;
        }
    }

    //salva i dati del player
    _save = () => {
        dispatcher.sendMessage(this._data, protocols.UserData);
        localStorage.setItem(protocols.UserData, JSON.stringify(this._data));
    }
}

export default new UserData();




//example:
// const exampleCharacter = {
//     name: 'Smork',
//     race: 'Orc',
//     classes: [{
//         name: 'Cleric',
//         level: 5,
//         modifier: 3,
//         proficency: 2,
//         color: 'gold'
//     }, {
//         name: 'Druid',
//         level: 2,
//         modifier: 3,
//         proficency: 2,
//         color: 'green'
//     }],
//     image: '/smork.png',
//     description: 'Once upon a time, a small orc ...',
//     knownSpells: [
//         { name: '', description: '',  },
//         { name: '', description: '',  },
//         { name: '', description: '',  },
//     ],
//     preparedSpells: [
//         { name: '', description: '',  },
//         { name: '', description: '',  }
//     ]
// }