import React from 'react'
import { FormControl, Select, MenuItem, InputLabel, TextField, Chip, Avatar, Collapse, Button, Typography } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { allClasses } from '../../data/GameData'
import { translate, getStringTranslation } from '../multiLanguage/Translator'
import { colors } from '../../helpers/Theme';

//todo: name, level, modifier, proficency, color
const CharacterClassSelection = ({classes, addClass, removeClass, character}) => {
    const [isNewOpen, setIsNewOpen] = React.useState(false);
    const [cClass, setClass] = React.useState(allClasses[0]);
    const [level, setLevel] = React.useState(1);
    const [modifier, setModifier] = React.useState(2);
    const [proficency, setProficency] = React.useState(2);
    const [color, setColor] = React.useState(classColors[0]);

    const isClickable = character !== null;

    const expandClick = () => {
        setClass(allClasses[0]);
        setLevel(1);
        setModifier(2);
        setProficency(2);
        setColor(classColors[0]);
        setIsNewOpen(!isNewOpen);
    }
    const handleDelete = (className) => removeClass(className);

    const myAddClass = (data) => {
        setIsNewOpen(false);
        addClass(data);
    }

    const chipClick = (className) => {
        if(!isClickable) return;

        const myClass = character.classes.find(x => x.name === className);
        setClass(myClass.name);
        setLevel(myClass.level);
        setModifier(myClass.modifier);
        setProficency(myClass.proficency);
        setColor(myClass.color);
        setIsNewOpen(true);
    }

    const addClassLabel = isNewOpen ? getStringTranslation('character.addClassLabelHide') : getStringTranslation('character.addClassLabel');

    return (
        <div style={{marginTop: 20}}>
            {classes.length === 0 && !isNewOpen && 
                <Typography style={{color: colors.gray}}>{translate('character.addClassSuggestion')}</Typography>}
            {classes.map((itm, i) => {
                return (
                    <React.Fragment key={i} >
                        <Chip 
                            style={{...classChip, ...{backgroundColor: itm.color}}}
                            color="secondary"
                            avatar={<Avatar>{itm.level}</Avatar>}
                            label={itm.name}
                            onDelete={() => handleDelete(itm.name)}
                            clickable={isClickable}
                            onClick={() => chipClick(itm.name)} />
                        <br />
                    </React.Fragment>
                )
            })}
            <Chip color="secondary" icon={<AddCircleIcon />} style={classChip} label={addClassLabel} clickable onClick={expandClick} />
            <Collapse in={isNewOpen} timeout="auto" unmountOnExit>
                <NewCharacterClass 
                    addClass={myAddClass}
                    cClass={cClass}
                    setClass={v => setClass(v)}
                    level={level} 
                    setLevel={v => setLevel(v)}
                    modifier={modifier}
                    setModifier={v => setModifier(v)}
                    proficency={proficency}
                    setProficency={v => setProficency(v)}
                    color={color}
                    setColor={v => setColor(v)} />
            </Collapse>
        </div>
    )
}

const NewCharacterClass = ({addClass, cClass, setClass, level, setLevel, modifier, setModifier, proficency, setProficency, color, setColor}) => {
    const handleChangeClass = (e) => setClass(e.target.value);
    const handleChangeLevel = (e) => setLevel(e.target.value);
    const handleChangeModifier = (e) => setModifier(e.target.value);
    const handleChangeProficency = (e) => setProficency(e.target.value);
    const handleChangeColor = (e) => setColor(e.target.value);

    const handleSubmit = () => {
        const newClass = {
            name: cClass,
            level: level,
            modifier: modifier,
            proficency: proficency,
            color: color
        };
        addClass(newClass);
    }

    return (
        <form>
            <FormControl style={formControl}>
                <InputLabel>{translate('character.classSelection')}</InputLabel>
                <Select value={cClass} onChange={handleChangeClass}>
                    {allClasses.map((itm, i) => {
                        return (
                            <MenuItem key={i} value={itm}>
                                {translate('classes.' + itm.toLowerCase())}
                            </MenuItem>
                        )
                    })} 
                </Select>
            </FormControl> <br />
            <FormControl style={formControl}>
                <InputLabel>{translate('character.levelSelection')}</InputLabel>
                <Select value={level} onChange={handleChangeLevel}>
                    {Array(20).fill(0).map((itm, i) => {
                        let lvl = i + 1;
                        return (
                            <MenuItem key={lvl} value={lvl}>{lvl}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl> <br />
            <FormControl style={formControl}>
                <TextField
                    label={getStringTranslation('character.modifierLabel')}
                    value={modifier}
                    onChange={handleChangeModifier}
                    type="number"
                    InputLabelProps={{shrink: true}}
                    margin="normal"/>
            </FormControl> <br />
            <FormControl style={formControl}>
                <TextField
                    label={getStringTranslation('character.proficencyLabel')}
                    value={proficency}
                    onChange={handleChangeProficency}
                    type="number"
                    InputLabelProps={{shrink: true}}
                    margin="normal"/>
            </FormControl> <br />
            <FormControl style={formControl}>
                <InputLabel>{translate('character.colorLabel')}</InputLabel>
                <Select value={color} onChange={handleChangeColor}>
                    {classColors.map((itm, i) => {
                        return (
                            <MenuItem key={i} value={itm}>
                                <Avatar style={{backgroundColor: itm}}></Avatar>
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl> <br />
            <Button style={{marginTop: 10}} color="secondary" variant="outlined" onClick={handleSubmit}>{translate('character.addClassButton')}</Button>
        </form>
    );
}

const classColors = [ '#C73032', '#E7623E', '#B59E54', '#AB6DAC', '#507F62', '#91A1B2', '#555752', '#7A853B', '#992E2E', '#7F513E', '#7B469B', '#51A5C5', '#2A50A1' ];

const formControl = {
    minWidth: 250, 
    margin: '2px 10px'
}

const classChip = {
    margin: '10px 10px 10px 0',
    fontSize: 16
}

export default CharacterClassSelection