import React from 'react'

import SpellCarousel from '../spellbook/SpellCarousel'
import { translate } from '../../multiLanguage/Translator'
import { colors } from '../../../helpers/Theme'
import SpellCarouselLevelSelection from '../spellbook/SpellCarouselLevelSelection'
import userData from '../../../data/UserData'
import dispatcher, { protocols } from '../../../communication/Dispatcher'
import SpellList from '../spellbook/SpellList'

const PreparedSpells = ({character, spells, characterSpellsChanged, editCharacterStats, cardVisualizationType, changeCardVisualizationType}) => {
    const [data, setData] = React.useState(spells);
    const [filteredLevel, setLevelFilter] = React.useState(-1);
    const availableLevels = [-1, ...Array.from(new Set(spells.map(x => x.level)))];

    dispatcher.sendMessage({
        showBanner: false
    }, protocols.Ads);

    const handleCharacterSpellsChanged = (spell, action, type) => {
        const prep = userData.getPreparedSpells(character.name);
        setData(prep);
        characterSpellsChanged(spell, action, type);
    }

    const filterByLevel = (level) => {
        if(level == -1) {
            setData(spells);
            setLevelFilter(-1);
        } else {
            const prepared = spells.filter(x => x.level == level);
            setData(prepared);
            setLevelFilter(level);
        }
    }

    if(spells.length > 0) {
        return (
            <React.Fragment>
                <SpellCarouselLevelSelection 
                    character={character}
                    filterByLevel={filterByLevel} 
                    availableLevels={availableLevels} 
                    selectedLevel={filteredLevel} 
                    editCharacterStats={editCharacterStats}
                    cardVisualizationType={cardVisualizationType}
                    changeCardVisualizationType={changeCardVisualizationType} />
                {cardVisualizationType === 'cards' ?
                    <SpellCarousel key="preparedSpellCarousel" character={character} cards={data} characterSpellsChanged={handleCharacterSpellsChanged} type="prepared" /> : 
                    <SpellList key="preparedSpellList" character={character} cards={data} characterSpellsChanged={handleCharacterSpellsChanged} type="prepared" /> }
            </React.Fragment>
        )
    } else {
        return (
            <div style={{height: '100%', width: '100%', textAlign: 'center', color: colors.gray}}>
                {translate('editCharacter.emptyPrepared')}
            </div>
        )
    }
}

export default PreparedSpells