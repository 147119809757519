import React from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { SwipeableDrawer, IconButton, Divider } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import SpellDetaillItem from './SpellDetailItem'
import SpellDetailNotFound from './SpellDetailNotFound'
import { isMobileQuery } from '../../helpers/JsFunctions'
import { colors } from '../../helpers/Theme'

const SpellDetail = (props) => {
    const [open, setOpen] = React.useState(false);
    const [firstTime, setFirstTime] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const isMobile = useMediaQuery({query: isMobileQuery});

    const selectSpell = (spell) => {
        handleOpen();
        props.history.push('/spells/' + spell.urlFriendlyName);
    }
    
    props.selectSpell(selectSpell);

    if(isMobile && firstTime) {
        let spellName = props.location.pathname.replace('/spells/', '').replace('/', '');
        if(spellName !== '') {
            setOpen(true);
        }
        setFirstTime(false);
    }

    if(isMobile) {
        return (
            <SwipeableDrawer anchor="right" open={open} onOpen={handleOpen} onClose={handleClose}>
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', backgroundColor: colors.lightGray}}>
                    <IconButton onClick={handleClose}>
                        <ChevronRightIcon />
                    </IconButton>
                </div>
                <Divider />
                <Switch>
                    <Route path={'/spells/:spellName'} render={(props) => <SpellDetaillItem {...props} />} />
                    <Route component={SpellDetailNotFound} />
                </Switch>
            </SwipeableDrawer>
        )
    } else {
        return (
            <Switch>
                <Route path={'/spells/:spellName'} render={(props) => <SpellDetaillItem {...props} />} />
                <Route component={SpellDetailNotFound} />
            </Switch>
        );
    }
}

export default withRouter(SpellDetail)
