import React, { Component } from 'react'
import { DialogContent, DialogActions, TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography } from '@material-ui/core'

import CharacterClassSelection from '../CharacterClassSelection'
import { getStringTranslation, translate } from '../../multiLanguage/Translator'
import StatusMessage, { variants } from '../../controls/StatusMessage'
import { allRaces } from '../../../data/GameData'
import userData from '../../../data/UserData'
import { remove } from '../../../helpers/JsFunctions'
import ResponsiveDialog from '../../controls/ResponsiveDialog'
import dispatcher, { protocols } from '../../../communication/Dispatcher'
import { colors } from '../../../helpers/Theme'

export class NewCharacter extends Component {
    state = {
        isOpen: false,
        name: '',
        race: allRaces[0],
        classes: [],
        image: '',
        description: ''
    };

    setName = (name) => {this.setState({name: name})};
    setRace = (race) => {this.setState({race: race})};
    setImage = (image) => {this.setState({image: image})};
    setDesc = (description) => {this.setState({description: description})};

    addClass = (newClass) => {
        let classes = [...this.state.classes];
        if(classes.map(x => x.name).indexOf(newClass.name) > -1) {
            this.show(getStringTranslation('character.duplicateClass'), 'warning');
            return;
        }
        classes.push(newClass);
        this.setState({classes: classes});
    };
    removeClass = (cName) => {
        let classes = remove(this.state.classes, { name: cName }, 'name');
        this.setState({classes: classes});
    }

    showPage = () => this.setOpen(true);
    setOpen = (isOpen) => this.setState({isOpen: isOpen});

    createCharacter = () => {
        const { name, race, classes, image, description } = this.state;
        //crea il nuovo personaggio con tutti i valori impostati
        if(name === '') {
            this.show(getStringTranslation('character.nameMissing'), variants.warning);
            return;
        }
        if(classes.length === 0) {
            this.show(getStringTranslation('character.classMissing'), variants.warning);
            return;
        }

        this.show(getStringTranslation('character.newCharacterCompleted'), variants.success);

        //creo il personaggio nei dati dell'utente
        userData.setCharacter(name, race, classes, image, description, [], []);
        
        this.props.refreshCharacters(name);
        this.setOpen(false);

        dispatcher.sendMessage({
            showBanner: false,
            showInterstitial: true
        }, protocols.Ads);
    }

    componentDidMount() {
        this.props.showPage(this.showPage)
    }

    render() {
        return (
            <React.Fragment>
                <NewCharacterUI
                    open={this.state.isOpen}
                    setOpen={this.setOpen}
                    setName={this.setName}
                    setRace={this.setRace}
                    addClass={this.addClass}
                    removeClass={this.removeClass}
                    setImage={this.setImage}
                    setDesc={this.setDesc}
                    race={this.state.race}
                    classes={this.state.classes}
                    createCharacter={this.createCharacter} />
                <StatusMessage show={s => this.show = s} />
            </React.Fragment>
        )
    }
}

const NewCharacterUI = ({open, setOpen, name, setName, race, setRace, classes, addClass, removeClass, image, setImage, desc, setDesc, createCharacter}) => {
    const handleNameChange = (e) => setName(e.target.value);
    const handleDescChange = (e) => setDesc(e.target.value);
    const handleRaceChange = (e) => setRace(e.target.value);
    const createClick = () => createCharacter();

    const body = (
        <DialogContent>
            <FormControl style={formControl}>
                <TextField 
                    name="name" 
                    label={getStringTranslation('character.nameLabel')} 
                    value={name} 
                    onChange={handleNameChange} 
                    margin="dense" />
            </FormControl> <br />
            {/* <FormControl style={formControl}>
                <TextField 
                    name="description"
                    label={getStringTranslation('character.descLabel')}
                    multiline
                    rowsMax="6"
                    value={desc} 
                    onChange={handleDescChange} 
                    margin="dense" />
            </FormControl> <br /> */}
            <FormControl style={formControl}>
                <InputLabel>{translate('character.raceLabel')}</InputLabel>
                <Select value={race} onChange={handleRaceChange}>
                    {allRaces.map((itm, i) => {
                        let race = itm.toLowerCase().replace('-', '');
                        return (
                            <MenuItem key={i} value={itm}>{translate('races.' + race)}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
            <CharacterClassSelection classes={classes} addClass={addClass} removeClass={removeClass} character={null} />
        </DialogContent>
    );

    const actions = (
        <DialogActions>
            <Typography style={{color: colors.gray, fontSize: 10}}>{translate('editCharacter.newCharacterAdInfo')}</Typography>
            <Button style={{marginTop: 20}} color="secondary" variant="contained" onClick={createClick}>{translate('character.createButton')}</Button>
        </DialogActions>
    );

    const content = (
        <React.Fragment>
            {body} {actions}
        </React.Fragment>
    );

    return (
        <ResponsiveDialog open={open} setOpen={setOpen} title="Create New Character">
            {content}
        </ResponsiveDialog>
    )
}


const formControl = {
    minWidth: 250
}

export default NewCharacter
