import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'

import SpellsTable from '../table/SpellsTable'
import SpellDetail from '../details/SpellDetail'
import ClassToolbar from '../classSelection/ClassToolbar'

export class DictionaryInterface extends Component {
    spellSelected = (spell) => {
        this.selectSpell(spell);
    }

    classSelected = (name) => {
        this.selectClass(name);
    }

    spellbookToggled = (isVisible) => {
        this.props.toggleSpellbook(isVisible);
    }

    selectedSpells = (spells) => {
        //quando la lista delle spell selezionate per lo spellbook cambia
        this.props.changeSpells(spells);
    }

    
    handleClose = () => this.setState({isSpellSelected: false});


    componentDidMount() {
        this.props.cardClicked(this.selectSpell);
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <ClassToolbar classSelected={this.classSelected} />
                </div>
                <div className="interface-container">    
                    <div className="interface-left">
                        <SpellsTable 
                            spellSelected={this.spellSelected} 
                            classSelected={c => this.selectClass = c} 
                            toggleSpellbook={this.spellbookToggled} 
                            selectionChanged={this.selectedSpells}
                            history={this.detailHistory} />
                    </div>
                    <div className="interface-right">
                        <BrowserRouter>
                            <SpellDetail 
                                selectSpell={s => this.selectSpell = s}
                                detailHistory={h => this.detailHistory = h} />
                        </BrowserRouter>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default DictionaryInterface
