import React from 'react'
import { Helmet } from 'react-helmet'
import { useMediaQuery } from 'react-responsive'

import SiteHeader from '../components/layout/SiteHeader'
import SiteFooter from '../components/layout/SiteFooter'
import { colors } from '../helpers/Theme'
import { isMobileQuery } from '../helpers/JsFunctions'
import dispatcher, {protocols} from '../communication/Dispatcher'

const Contact = () => {
    const isMobile = useMediaQuery({query: isMobileQuery});
    
    dispatcher.sendMessage({
        showBanner: true
    }, protocols.Ads);

    const container = {
        minHeight: 'calc(100vh - 85px)', 
        textAlign: 'left', 
        padding: isMobile === false ? '40px 80px' : '20px 10px'
    }

    return (
        <div className="app-container">
            <SiteHeader />
            <Helmet>
                <title>Contact | DnD Spells List</title>
                <meta name="description" content="Just send an email at qubitart.info@gmail.com for all your D&D spells needs." />
            </Helmet>
            <div style={container}>
                <h1 style={{color: colors.red}}>Contact</h1>
                <p>
                    If anything happens with the app or if you just wanna say "hi", send an email at: qubitart.info@gmail.com.
                    We will try to reply to you as soon as possible.
                </p>
            </div>
            <SiteFooter />
        </div>
    )
}


export default Contact