import React from 'react'
import { Divider } from '@material-ui/core'
import { Helmet } from 'react-helmet'

import SiteHeader from '../components/layout/SiteHeader'
import SiteFooter from '../components/layout/SiteFooter'
import { colors } from '../helpers/Theme'
import dispatcher, {protocols} from '../communication/Dispatcher'

import classSelector from '../images/guide/class-selector.png'
import classSelectorBard from '../images/guide/class-selector-bard.png'
import classSelectorBarbarian from '../images/guide/class-selector-barbarian.png'
import classSelectorMutiple from '../images/guide/class-selector-multiple.png'
import ascendingSort from '../images/guide/ascending-sort.png'
import descendingSort from '../images/guide/descending-sort.png'
import search from '../images/guide/search.png'
import spellDetail from '../images/guide/spell-detail.png'
import filterColumns from '../images/guide/filter-columns.png'
import spellbookSelection from '../images/guide/spellbook-selection.png'
import spellsSelected from '../images/guide/spells-selected.png'
import spellbook from '../images/guide/spellbook.png'
import exportCards from '../images/guide/export-cards.png'
import exportList from '../images/guide/export-list.png'
import exportCardPagebreak from '../images/guide/export-card-pagebreak.png'
import exportCardSplitted from '../images/guide/export-card-splitted.png'

const Guide = () => {

    dispatcher.sendMessage({
        showBanner: true
    }, protocols.Ads);

    return (
        <div className="app-container">
            <Helmet>
                <title>Guide | DnD Spells List</title>
                <meta name="description" content="Create your spellbook by selecting the spells that you want from the table, export them as cards or as a list." />
            </Helmet>
            <SiteHeader />
            <div className="guide-container">
                <h1 style={{color: colors.red}}>A Super Quick Guide</h1>
                <Divider style={{height: 2, backgroundColor: colors.yellow}} />
                <p>
                    Using the <strong>D&D Spells List</strong> web app is super easy, the interface is self explanatory and user friendly, but here we will go ahead and see all of it's features.
                </p>
                <h3>Filter by class</h3>
                <div>
                    In the top section of the main page, you can <em>filter all the spells of a specific class</em> by clicking on the 'chip' of that card. 
                    If you are playing a specific character you can only show the spells for his specific class, the app contains the spells for the main casters but also for all the others classes: <strong>Bard Spells</strong>, <strong>Cleric Spells</strong>, <strong>Druid Spells</strong>, <strong>Paladin Spells</strong>, <strong>Ranger Spells</strong>, <strong>Sorcerer Spells</strong>, <strong>Warlock Spells</strong> and <strong>Wizard Spells</strong>.
                    <GuideImage src={classSelector} altText={"Class filter selector"} />
                    Once you have clicked a class you will only see the spells available to that specific class ex.:
                    <GuideImage src={classSelectorBard} altText={"Filtered bard"} />
                    <GuideImage src={classSelectorBarbarian} altText={"Filtered barbarian"} />
                    Poor Barbarians ...
                    <GuideImage src={classSelectorMutiple} altText={"Filtered multiple classes"} />
                    You can also <strong>select multiple classes</strong> at once and all the spells from each specific class gets combined, for that <strong>spicy multi-class!</strong>
                </div>

                <br />
                <Divider style={{height: 2, backgroundColor: colors.yellow}} />
                <h3>Filter column</h3>
                <div>
                    Each column can be filtered, click under the column name to open the filter select option (except for the level in which you can just write the level) and select the filter that you would like to apply (even multiple filters can be applied at once):
                    <GuideImage src={filterColumns} altText={"Filter columns"} />
                    In this way you will show only the spells of fifth level that are rituals. <br />
                    You can filter by: spell level, school, ritual and concentration (more advanced filters to come!).
                </div>

                <br />
                <Divider style={{height: 2, backgroundColor: colors.yellow}} />
                <h3>Sort column</h3>
                <div>
                    By clicking on the header of a column you can sort it: click it once and it gets sorted by ascending order, click it twice and it gets sorted by descending order, click it again and the sorting returns to the default one.
                    <GuideImage src={ascendingSort} altText={"Sort columns ascending"} />
                    <GuideImage src={descendingSort} altText={"Sort columns descending"} />
                </div>

                <br />
                <Divider style={{height: 2, backgroundColor: colors.yellow}} />
                <h3>Search</h3>
                <div>
                    You can search any spell you want by typing part of it's name in the search textbox (it is case-insensitive):
                    <GuideImage src={search} altText={"Search spells"} />
                </div>

                <br />
                <Divider style={{height: 2, backgroundColor: colors.yellow}} />
                <h3>Spell details</h3>
                <div>
                    By clicking on a spell in the list you can see it's full details: <em>school, casting time, level, range, components, save throw (if required), damage type (is any), the spell description, what happens if you cast it at higher levels (if anything), the page in the book (for reference) and which classes can cast this spell</em>.
                    <GuideImage src={spellDetail} altText={"Spell detail"} />
                </div>

                <br />
                <br />
                <Divider style={{height: 2, backgroundColor: colors.yellow}} />
                <h3>Spellbook creation</h3>
                <div>
                    If you click the "Toggle Spellbook Selection" button, you will enter spell selection mode:
                    <GuideImage src={spellbookSelection} altText={"Spell selection mode"} />
                    You can now click on the square button on the left of the spell to select it (all the previous selections are rememered as well as sorts and filters):
                    <GuideImage src={spellsSelected} altText={"Selected spells"} />
                    The spells that you select get added to your spellbook, which you can see at the bottom of the page:
                    <GuideImage src={spellbook} altText={"Spellbook"} />
                    You are now ready to export your spellbook!
                </div>

                <br />
                <Divider style={{height: 2, backgroundColor: colors.yellow}} />
                <h3>Exporting your spellbook</h3>
                <div>
                    After you have selected the spells that you want to add to your spellbook you can press on the "EXPORT SPELLBOOK" button, which will open a new page with all your selected spells. <br />
                    The default visualization shows the selected spells as cards:
                    <GuideImage src={exportCards} altText={"Export cards"} />
                    But you can change them to be shown as a list:
                    <GuideImage src={exportList} altText={"Export list"} />
                    <br />
                    If you encounter a gray line, it shows where the printed page will break and a new page will start:
                    <GuideImage src={exportCardPagebreak} altText={"Export cards pagebreak"} />
                    If the spell description is too long, more cards will be created:
                    <GuideImage src={exportCardSplitted} altText={"Export cards splitted"} />
                    Now if you click on the "EXPORT" button at the bottom of the page a pdf will be created and automatically downloaded with the spells as they are shown in the page (works even on mobile!).
                </div>

                <br />
                <p>
                    If you have any question or encounter any problem feel free to contact me at: qubitart.info@gmail.com
                </p>
            </div>
            <SiteFooter />
        </div>
    );
}


const GuideImage = ({src, altText}) => {
    return (
        <div style={{textAlign: 'left', marginLeft: 5}}>
            <img src={src} alt={altText} className="guide-image" />
        </div>
    )
}


export default Guide;