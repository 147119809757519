import React from 'react'
import Translate from 'react-translate-component'
import counterpart from 'counterpart'

export const languages = {
    en: 'en',
    it: 'it'
}

//la registrazione della traduzione e le funzioni per prendere le traduzioni devono stare nella stessa pagina
counterpart.registerTranslations(languages.en, {
    classes: {
        barbarian: 'Barbarian',
        bard: 'Bard',
        cleric: 'Cleric',
        druid: 'Druid',
        fighter: 'Fighter',
        monk: 'Monk',
        paladin: 'Paladin',
        ranger: 'Ranger',
        rogue: 'Rogue',
        sorcerer: 'Sorcerer',
        warlock: 'Warlock',
        wizard: 'Wizard',
        expandLabel: 'Open filter by class'
    },
    spellDetail: {
        placeholder: 'Select a spell to see it\'s details',
        schoolLabel: 'School',
        levelLabel: 'Level',
        castingLabel: 'Casting Time',
        rangeLabel: 'Range',
        durationLabel: 'Duration',
        componentsLabel: 'Components',
        saveThrowLabel: 'Save',
        damageTypeLabel: 'Damage Type',
        showDetails: 'Show details'
    },
    spell: {
        school: {
            abjuration: 'Abjuration', 
            conjuration: 'Conjuration', 
            divination: 'Divination', 
            enchantment: 'Enchantment', 
            evocation: 'Evocation', 
            illusion: 'Illusion', 
            necromancy: 'Necromancy', 
            transmutation: 'Transmutation'
        },
        damageType: {
            acid: 'Acid', 
            bludgeoning: 'Bludgeoning', 
            cold: 'Cold', 
            fire: 'Fire', 
            force: 'Force', 
            lightning: 'Lightning', 
            necrotic: 'Necrotic', 
            piercing: 'Piercing', 
            poison: 'Poison', 
            psychic: 'Psychic', 
            radiant: 'Radiant', 
            slashing: 'Slashing', 
            thunder: 'Thunder'
        },
        ritual: 'Ritual',
        concentration: 'Concentration'
    },
    text: {
        yes: 'Yes',
        no: 'No'
    },
    table: {
        toggleSelection: 'Create your Spellbook',
        unToggleSelection: 'Stop spellbook creation',
        filterDamageType: 'Filter by damage type',
        favouriteSelection: 'Show only favourites',
        unFavouriteSelection: 'Show all spells',
        filterClass: 'Filter class',
        filterBook: 'Filter book'
    },
    spellbook: {
        longInstructions: 'DnD Spells List enables your to create your own spellbook for your character! Click on the "Create your Spellbook" red button in the top of the table to enter in spellbook selection mode. Once you are in, you can select the spells that you like by clicking on the checkbokx in the left of the spell in the table, in this way, it will get added to your spellbook in this section of the page. Roll initiative and have fun!',
        instruction: 'Select the spells from the list to add them to your spellbook',
        buildSpellbook: 'Build spellbook from selected spells'
    },
    footer: {
        homeLink: 'D&D spells list',
        aboutLink: 'Learn about the spell index',
        guideLink: 'How to use the D&D 5e spell list',
        contactLink: 'Contact DnD Spells List',
        characterManager: 'Character Manager',
        privacyLink: 'Privacy Policy'
    },
    export: {
        button: 'Create printable spellbook'
    },
    races: {
        dragonborn: 'Dragonborn',
        dwarf: 'Dwarf',
        elf: 'Elf',
        gnome: 'Gnome',
        halfelf: 'Half-Elf',
        halfling: 'Halfling',
        halforc: 'Half-Orc',
        human: 'Human',
        tiefling: 'Tiefling'
    },
    character: {
        classSelection: 'Select the class',
        levelSelection: 'Select the level',
        modifierLabel: 'Set the spellcasting ability modifier',
        proficencyLabel: 'Set the proficency',
        colorLabel: 'Select the color for the character',
        nameLabel: 'Name',
        descLabel: 'Backstory',
        createButton: 'Create a new character',
        nameMissing: 'You have to enter a name',
        classMissing: 'You have to select at least one class',
        addClassButton: 'Confirm class',
        duplicateClass: 'You already have this class selected',
        raceLabel: 'Select the race',
        addClassLabel: 'Add a class',
        addClassLabelHide: 'Close',
        newCharacterCompleted: 'New character succesfully created!',
        characterSelectLabel: 'Character selection',
        addClassSuggestion: 'You can select any class you like, we will not stop you from adding spells even to a Barbarian'
    },
    editCharacter: {
        preparedPage: 'Prepared spells',
        knownPage: 'Known spells',
        allPage: 'All spells',
        addSelectedSpellsLabel: 'Add selected spells to known and prepared spells',
        removeFromKnown: 'Forget',
        addToPrepared: 'Prepare',
        removeFromPrepared: 'Un-Prepare',
        castSpell: 'Cast',
        emptyKnown: 'Select and add spells from the "All Spells" tab',
        emptyPrepared: 'Select spells from the "All Spells" tab or prepare them from the "Known spells" tab',
        spellsAddedSuccess: 'Spells succesfully added to the character',
        spellsAddedNotEnoughSpells: 'Select at least one spell',
        newCharacterTitle: 'Add New Character',
        newCharacterDesc: 'Start creating a new character with his spellbook!',
        editStats: 'Click on a class to edit its properties, or add or remove an existing one.',
        deleteCharacter: 'Are you sure you want to delete ',
        newCharacterAdInfo: '* after creating a new character a brief ad will be shown, thank you for supporting us.'
    },
    mobileSuggest: {
        title: 'Do you know we also have an app?',
        text: 'Hello! We also have a mobile app: D&D Spells List that you can find on the Google Play Store.',
        confirm: 'Nice! Take me there.',
        abort: 'Nope, keep using the web version.'
    },
    books: {
        playershandbook: 'Players Handbook',
        elementalevil: 'Elemental Evil',
        swordcoastadventureguide: 'Sword Coast adv. guide',
        xanatharsguidetoeverything: 'Xanathars Guide To Everything'
    }
});
   
counterpart.registerTranslations(languages.it, {
    classes: {
        barbarian: 'Barbaro',
        bard: 'Bardo',
        cleric: 'Chierico',
        druid: 'Druido',
        fighter: 'Guerriero',
        monk: 'Monaco',
        paladin: 'Paladino',
        ranger: 'Ranger',
        rogue: 'Ladro',
        sorcerer: 'Stregone',
        warlock: 'Warlock',
        wizard: 'Mago',
        expandLabel: 'Apri filtro per classe'
    },
    spellDetail: {
        placeholder: 'Seleziona un incantesimo per vedere i suoi dettagli',
        schoolLabel: 'Scuola',
        levelLabel: 'Livello',
        castingLabel: 'Tempo di lancio',
        rangeLabel: 'Gittata',
        durationLabel: 'Durata',
        componentsLabel: 'Componenti',
        saveThrowLabel: 'Tiro Salvezza',
        damageTypeLabel: 'Tipo Danno',
        showDetails: 'Mostra dettagli'
    },
    spell: {
        school: {
            abjuration: 'Abiurazione', 
            conjuration: 'Evocazione', 
            divination: 'Divinazione', 
            enchantment: 'Ammaliamento', 
            evocation: 'Invocazione', 
            illusion: 'Illusione', 
            necromancy: 'Necromanzia', 
            transmutation: 'Trasmutazione'
        },
        damageType: {
            acid: 'Acido', 
            bludgeoning: 'Contundenti', 
            cold: 'Freddo', 
            fire: 'Fuoco', 
            force: 'Radiosi', 
            lightning: 'Fulmine', 
            necrotic: 'Necrotici', 
            piercing: 'Perforanti', 
            poison: 'Velenosi', 
            psychic: 'Psichici', 
            radiant: 'Radianti', 
            slashing: 'Taglienti', 
            thunder: 'Tuono'
        },
        ritual: 'Rituale',
        concentration: 'Concentrazione'
    },
    text: {
        yes: 'Si',
        no: 'No'
    },
    table: {
        toggleSelection: 'Crea il tuo Spellbook',
        unToggleSelection: 'Completa creazione Spellbook',
        filterDamageType: 'Filtra per i tipi di danno',
        favouriteSelection: 'Mostra solo i preferiti',
        unFavouriteSelection: 'Mostra tutti gli incantesimi',
        filterClass: 'Filtra la classe',
        filterBook: 'Filtra per libro'
    },
    spellbook: {
        longInstructions: 'DnD Spells List ti permette di creare lo spellbook per il tuo personaggio! Premi sul pulsante rosso "Crea il tuo Spellbook" nella testata della tabella ed entra nella modalità di selezione degli incantesimi. Una volta che è attiva, puoi selezionare le magie che preferisci cliccando nella checkbox sulla sinistra della tabella, in questo modo, l\'incantesimo verrà aggiunto al tuo spellbook in questa sezione della pagina. Tira per iniziativa e divertiti!',
        instruction: 'Selezione gli incantesimi dalla lista per inserirli nel tuo spellbook',
        buildSpellbook: 'Crea lo spellbook dagli incantesimi selezionati'
    },
    footer: {
        homeLink: 'D&D spells list',
        aboutLink: 'Scopri le informazioni riguardo la lista degli incantesimi',
        guideLink: 'Come usare la lista degli incantesimi di D&D 5e',
        contactLink: 'Contatta DnD Spells List',
        characterManager: 'Gestione Personaggio',
        privacyLink: 'Privacy Policy'
    },
    export: {
        button: 'Crea spellbook stampabile'
    },
    races: {
        dragonborn: 'Dragonide',
        dwarf: 'Nano',
        elf: 'Elfo',
        gnome: 'Gnomo',
        halfelf: 'Mezzelfo',
        halfling: 'Halfling',
        halforc: 'Mezzorco',
        human: 'Umano',
        tiefling: 'Tiefling'
    },
    character: {
        classSelection: 'Seleziona la classe',
        levelSelection: 'Seleziona il livello',
        modifierLabel: 'Imposta il modificatore dell\'abilità da incantatore',
        proficencyLabel: 'Imposta la proficency',
        colorLabel: 'Seleziona il colore per il personaggio',
        nameLabel: 'Nome',
        descLabel: 'Storia',
        createButton: 'Crea un nuovo personaggio',
        nameMissing: 'Devi inserire un nome',
        classMissing: 'Devi selezionare almeno una classe',
        addClassButton: 'Conferma classe',
        duplicateClass: 'Hai già questa classe selezionata',
        raceLabel: 'Seleziona la razza',
        addClassLabel: 'Aggiungi una classe',
        addClassLabelHide: 'Annulla',
        newCharacterCompleted: 'Nuovo personaggio creato!',
        characterSelectLabel: 'Selezione personaggio',
        addClassSuggestion: 'Puoi selezionare qualsiasi classe, non ti impediremo di aggiungere magie, nemmeno ad un Barbaro'
    },
    editCharacter: {
        preparedPage: 'Incantesimi preparati',
        knownPage: 'Incantesimi conosciuti',
        allPage: 'Tutti gli incantesimi',
        addSelectedSpellsLabel: 'Aggiungi agli incantesimi conosciuti e preparati',
        removeFromKnown: 'Dimentica',
        addToPrepared: 'Prepara',
        removeFromPrepared: 'Rimuovi',
        castSpell: 'Lancia',
        emptyKnown: 'Seleziona e aggiungi incantesimi dalla tab "Tutti gli incantesimi"',
        emptyPrepared: 'Seleziona e aggiungi incantesimi dalla tab "Tutti gli incantesimi" o preparali dalla tab "Incantesimi conosciuti"',
        spellsAddedSuccess: 'Incantesimi aggiunti correttamente',
        spellsAddedNotEnoughSpells: 'Seleziona almeno un incantesimo',
        newCharacterTitle: 'Aggiungi un nuovo personaggio',
        newCharacterDesc: 'Crea un nuovo personaggio con il suo libro degli incantesimi!',
        editStats: 'Clicca su una classe per modificare le sue proprietà o aggiungine o rimuovine una.',
        deleteCharacter: 'Sei sicuro che vuoi cancellare ',
        newCharacterAdInfo: '* dopo aver creato un nuovo personaggio, una piccola pubblicità verrà mostrata, grazie per supportarci.'
    },
    mobileSuggest: {
        title: 'Lo sai che abbiamo un\'app?',
        text: 'Ciao! Abbiamo anche una app mobile: D&D Spells List, puoi trovarla sul Google Play Store.',
        confirm: 'Ottimo! Portami lì.',
        abort: 'No, continua con la versione web.'
    },
    books: {
        playershandbook: 'Manuale del Giocatore',
        elementalevil: '',
        swordcoastadventureguide: '',
        xanatharsguidetoeverything: ''
    }
});


//crea un oggetto html che automaticamente cambia il contenuto al cambio della lingua
export const translate = (toTranslate) => {
    return <Translate content={toTranslate} />;
}


//traduce letteralmente una stringa
export const getStringTranslation = (toTranslate) => {
    let translation = counterpart.translate(toTranslate);
    return translation;
}


export const getLocale = () => counterpart.getLocale();
export const getMetaTagLocale = () => {
    switch(getLocale()) {
        case languages.en: return 'en';
        case languages.it: return 'it';
        default: return 'en';
    }
}