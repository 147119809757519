import React from 'react'
import { Divider, Grid } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { colors } from '../helpers/Theme'
import SiteHeader from '../components/layout/SiteHeader'
import SiteFooter from '../components/layout/SiteFooter'
import { translate } from '../components/multiLanguage/Translator'
import ClassSelector from '../components/classSelection/ClassSelector'
import { getSpells } from '../data/SpellsData'

import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from '../helpers/JsFunctions'  
import dispatcher, {protocols} from '../communication/Dispatcher'

const About = () => {
    const isMobile = useMediaQuery({query: isMobileQuery});

    dispatcher.sendMessage({
        showBanner: true
    }, protocols.Ads);

    return (
        <div className="app-container">
            <SiteHeader />
            {/* {getLocale() === languages.it ? 
                <AboutIt /> : 
                <AboutEn />} */}
            <AboutEn isMobile={isMobile} />
            <SiteFooter isMobile={isMobile} />
        </div>
    );
}

// const AboutIt = ({isMobile}) => {
//     return (
//         <React.Fragment>
            
//         </React.Fragment>
//     )
// }

const AboutEn = ({isMobile}) => {
    return (
        <React.Fragment>
            <Helmet>
                <title>About | DnD Spells List</title>
                <meta name="description" content="DnD Spells List contains all of the Dungeons & Dragons (D&D) 5e spells, you can search them, create spellbooks, export and print them." />
            </Helmet>
            <div className="about-container">
                <div className="about-title">
                    <h1 style={{color: colors.red, marginTop: 0}}>Dungeons & Dragons (D&D) Spells, Spellbooks and Printables</h1>
                    <h3>Search your spells quickly by school or by class, save them into your spellbook</h3>
                </div>
                <div className="about-abstract">
                    <p>
                        Create your <strong style={{color: colors.red}}>spellbook</strong>: <em>select, save and export</em> your spells to
                        <strong style={{color: colors.red}}> pdf or print</strong> them. Export to spell <strong style={{color: colors.red}}>cards or spellbook</strong> (spell list)
                    </p>
                </div>
                <Divider style={{height: 2, backgroundColor: colors.yellow}} />
                <div className="about-content">
                    <div>
                        <p>
                            Search the spells that you want for your character in an easy and fast way, with DnDSpellsList.com the focus is on the ease of use. 
                            Search for the spell via it's name, filter by Level, Class, School, Damage Type, Concentration and Ritual.
                            Sort them easily to quickly find the spells that you are looking for. <br />
                            The spells are available for all the classes:
                        </p>
                        <ul>
                            <li style={{margin: 5}}><ClassSelector logo={'Barbarian'} text={translate('classes.barbarian')} isClickable={false} /></li>
                            <li style={{margin: 5}}><ClassSelector logo={'Bard'} text={translate('classes.bard')} isClickable={false} /></li>
                            <li style={{margin: 5}}><ClassSelector logo={'Cleric'} text={translate('classes.cleric')} isClickable={false} /></li>
                            <li style={{margin: 5}}><ClassSelector logo={'Druid'} text={translate('classes.druid')} isClickable={false} /></li>
                            <li style={{margin: 5}}><ClassSelector logo={'Fighter'} text={translate('classes.fighter')} isClickable={false} /></li>
                            <li style={{margin: 5}}><ClassSelector logo={'Monk'} text={translate('classes.monk')} isClickable={false} /></li>
                            <li style={{margin: 5}}><ClassSelector logo={'Paladin'} text={translate('classes.paladin')} isClickable={false} /></li>
                            <li style={{margin: 5}}><ClassSelector logo={'Ranger'} text={translate('classes.ranger')} isClickable={false} /></li>
                            <li style={{margin: 5}}><ClassSelector logo={'Rogue'} text={translate('classes.rogue')} isClickable={false} /></li>
                            <li style={{margin: 5}}><ClassSelector logo={'Sorcerer'} text={translate('classes.sorcerer')} isClickable={false} /></li>
                            <li style={{margin: 5}}><ClassSelector logo={'Warlock'} text={translate('classes.warlock')} isClickable={false} /></li>
                            <li style={{margin: 5}}><ClassSelector logo={'Wizard'} text={translate('classes.wizard')} isClickable={false} /></li>
                        </ul>
                    </div>
                    <p>
                        After you have created your spellbook you can export it as <strong style={{color: colors.red}}>Spell Cards</strong> or as a <strong style={{color: colors.red}}>Spellbook (list of spells)</strong>.
                        You can then <strong style={{color: colors.red}}>print</strong> your spellbook and use it at the table!
                    </p>
                    <div>
                        Also <em>DnDSpellsList.com</em> supports multiple languages: <br /><br />
                        <Grid container spacing={8} justify='center' style={{flexGrow: 1}} >
                            <SpellsPerLanguage lang='en' isMobile={isMobile} />
                            <SpellsPerLanguage lang='it' isMobile={isMobile} />
                        </Grid>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const SpellsPerLanguage = ({lang, isMobile}) => {
    const spells = getSpells(lang);
    return (
        <Grid item xs={6}>
            <div style={spellCircleDisplay}>
                <h3 style={spellCircleLang}>{lang}</h3>
                <h2 style={spellCircleNumber}>{spells.length}</h2>
                <p style={spellCircleLabel}>spells</p>
            </div>
            <div>
                <ul>
                    {spells.map((s, i) => {
                        const link = '/spells/' + s.urlFriendlyName;
                        return (
                            <li key={i} style={isMobile ? spellLiMobile : {}}><em><Link to={link} style={spellLink}>{s.name}</Link></em></li>
                        )
                    })}
                </ul>
            </div>
        </Grid>
    )
}

const spellCircleDisplay = {
    borderRadius: '50%', borderColor: colors.red, borderWidth: 4, borderStyle: 'solid', textAlign: 'center', height: 128, width: 128
}
const spellCircleLang = {
    textTransform: 'uppercase'
}
const spellCircleNumber = {
    marginBottom: 0
}
const spellCircleLabel = {
    margin: 0
}
const spellLiMobile = {
    marginTop: 10 
}
const spellLink = {
    fontSize: 14, color: colors.darkGray
}

export default About;