import React from 'react'

const avatar = {
    objectFit: 'contain',
    margin: 5,
    height: 32,
    width: 32,
};

const LogoImmage = React.memo(function LogoImmage(props) {
    const { logo } = props;
    return (
        <img src={logo} style={avatar} alt={logo} />
    );
});

export default LogoImmage
