import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import ExportCustomizer from './customizer/ExportCustomizer'
import ExportRender from './ExportRender'
import { Button } from '@material-ui/core';
import { exportToPdf } from './ExportToPdf'
import SiteHeader from '../layout/SiteHeader'
import SiteFooter from '../layout/SiteFooter'
import { translate } from '../multiLanguage/Translator'

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

const theme = createMuiTheme({
    palette: {
        secondary: { main: '#A70000' }
    }
})

export class ExportSpellbook extends Component {    
    componentWillMount() {
        const rawData = localStorage.getItem('spellbook');
        this.groupedSpells = JSON.parse(rawData);
    }

    export = () => exportToPdf(this.toPrint);

    updateCardDimensions = (width, height) => this.dimensionUpdated(width, height);
    
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Helmet>
                    <title>DnD Spells List | Spell index, spellbook, spell cards for Dungeons & Dragons</title>
                    <meta name="description" content="Dungeons and Dragons (D&D, DnD) Fifth Edition (5e) spells. A list of all the spells, spell index, create spellbooks, print them as cards or as a list" />
                </Helmet>
                <div className="app-container">
                    <SiteHeader />
                    <ExportCustomizer updateDimensions={this.updateCardDimensions} spellbookTypeChanged={(t) => this.spellbookTypeChanged(t)} />
                    <div>
                        <Button variant="contained" color="secondary" onClick={this.export}>{translate('export.button')}</Button>
                    </div>
                    <ExportRender groupedSpells={this.groupedSpells} setToPrint={s => this.toPrint = s} dimensionUpdated={d => this.dimensionUpdated = d} spellbookTypeChanged={s => this.spellbookTypeChanged = s} />
                    <SiteFooter />
                </div>
            </ThemeProvider>
        )
    }
}

export default ExportSpellbook
