//tutti i tipi di messaggio che possono essere scambiati con il frame
export const protocols = {
    UserData: 'UserData',
    AppData: 'AppData',
    Ads: 'Ads'
}

let instance = null;
class Dispatcher {
    constructor() {
        if (!instance) {
            instance = this;
        }

        this._subscribers = [];
        document.handleCustomEvent = this._onMessage;

        return instance;
    }

    subscribe = (name, callback) => {
        let index = this._subscribers.findIndex(x => x.name === name);
        if(index > -1) {
            this._subscribers.splice(index, 1);
        }
        this._subscribers.push({name: name, callback: callback});
    }

    sendMessage = (data, protocol) => {
        const message = {...data, protocol};
        let serialized = JSON.stringify(message);
        if(window.ReactNativeWebView !== undefined) {
            window.ReactNativeWebView.postMessage(serialized);
        }
    }

    _onMessage = (message) => {
        if(message.protocol !== undefined) {    //mando avanti il messaggio solo se è destinato all'applicazione
            // alert(JSON.stringify(message));
            this._subscribers.forEach(sub => {
                sub.callback(message, message.protocol);
            });
        }
    }
}

export default new Dispatcher();