import React from 'react'
import { Paper, Grid, CardHeader, IconButton } from '@material-ui/core';
import StarsIcon from '@material-ui/icons/Stars';
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import ClassSelector from '../classSelection/ClassSelector'
import { translate, getStringTranslation } from '../multiLanguage/Translator'
import { getSpellData } from '../../data/SpellsData'
import { getClassKeyFromTranslation } from '../../data/GameData'
import SpellDetailPageHeader from './SpellDetailPageHeader'
import { isMobileQuery } from '../../helpers/JsFunctions'
import { colors } from '../../helpers/Theme'
import userData from '../../data/UserData'

const SpellDetailItem = (props) => {
    const isMobile = useMediaQuery({query: isMobileQuery});
    const paper = {
        padding: isMobile ? '0px 12px 12px 12px' : 20
    }

    const spell = getSpellData(props.match.params.spellName);
    if(spell === null || spell === undefined) {
        return (
            <Paper style={paper}>
                Ops... something went wrong, please write en email at qubitart.info@gmail.com explaining the error.
                We suggest that you go to the home page (<Link to='/'>dndspellslist.com</Link>) and then retry searching for the spell.
                Thank you for your patience and sorry for the inconvinience :)
            </Paper>
        )
    }

    const { 
        name, level, school, 
        castingTime, ritual, range, 
        components, duration, description, 
        atHigherLevels, availableClasses, sourcePage,
        damageTypes, savingThrows, language
    } = spell;
    const comps = components.join(', ');
    const damageTypeLabel = damageTypes.join(', ');
    const saveLabel = savingThrows.join(', ');
    return (
        <React.Fragment>
            <SpellDetailPageHeader name={name} school={school} castingTime={castingTime} availableClasses={availableClasses} description={description} language={language} />
            <Paper style={paper}>
                <SpellDetailTitle spell={spell} name={name} ritual={ritual} />
                <Grid container direction="row" justify="space-between" className="spell-table" spacing={1}>
                    <Grid item xs={12} sm={6}>{getStringTranslation('spellDetail.schoolLabel')}: <strong>{school}</strong></Grid>
                    <Grid item xs={12} sm={6}>{getStringTranslation('spellDetail.levelLabel')}: <strong>{level}</strong></Grid>
                    <Grid item xs={12} sm={6}>{getStringTranslation('spellDetail.castingLabel')}: <strong>{castingTime}</strong></Grid>
                    <Grid item xs={12} sm={6}>{getStringTranslation('spellDetail.rangeLabel')}: <strong>{range}</strong></Grid>
                    <Grid item xs={12} sm={6}>{getStringTranslation('spellDetail.durationLabel')}: <strong>{duration}</strong></Grid>
                    <Grid item xs={12} sm={6}>{getStringTranslation('spellDetail.componentsLabel')}: <strong>{comps}</strong></Grid>
                    <Grid item xs={12} sm={6}>{getStringTranslation('spellDetail.saveThrowLabel')}: <strong style={{textTransform: 'capitalize'}}>{saveLabel}</strong></Grid>
                    <Grid item xs={12} sm={6}>{getStringTranslation('spellDetail.damageTypeLabel')}: <strong style={{textTransform: 'capitalize'}}>{damageTypeLabel}</strong></Grid>
                </Grid>
                <p className="spell-description">
                    {description}
                </p>
                {atHigherLevels !== '' &&
                    <React.Fragment>
                        <h5 className="spell-higher-title">At Higher Levels</h5>
                        <p className="spell-higher-text">{atHigherLevels}</p>
                    </React.Fragment>}
                <p className="spell-source">
                    {sourcePage}
                </p>
                <SpellDetailClasses isMobile={isMobile} availableClasses={availableClasses} />
            </Paper>
        </React.Fragment>
    );
}


const SpellDetailTitle = ({spell, name, ritual}) => {
    const [isFavourite, setFavourite] = React.useState(false);
    const title = name + (ritual ? ' (Ritual)' : '');

    userData.whenReady(() => {
        let fav = userData.isFavouriteSpell(spell);
        if(fav !== isFavourite)
            setFavourite(fav);
    });

    const onClick = () => {
        setFavourite(!isFavourite);
        userData.favouriteSpell(spell);
    }

    return (
        <CardHeader 
            className="spell-name" 
            color="secondary" 
            disableTypography={true}
            title={<h1 className="spell-name">{title}</h1>} 
            action={
                <IconButton onClick={onClick}>
                    <StarsIcon htmlColor={isFavourite ? colors.goldYellow : ''} titleAccess='Favourite' />
                </IconButton>
            } />
    )
}


const SpellDetailClasses = ({isMobile, availableClasses}) => {
    return (
        <div style={isMobile ? {textAlign: 'center'}: {}}>
            {availableClasses.map(itm => {
                const spellClass = getClassKeyFromTranslation(itm);
                return (
                    <span key={itm} style={{margin: 10}}>
                        <ClassSelector text={translate('classes.' + spellClass.toLowerCase())} logo={spellClass} isClickable={false} />
                    </span>
                )
            })}
        </div>
    )
}


export default SpellDetailItem;