import React from 'react'
import { ListItem, ListItemAvatar, ListItemText, Collapse, Avatar, Typography, Paper, Grid, Divider, CardActions } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import ProgressButton from '../../controls/ProgressButton'
import userData from '../../../data/UserData'
import { translate } from '../../multiLanguage/Translator'
import { colors } from '../../../helpers/Theme'

const SpellListItem = ({character, spell, type, characterSpellsChanged}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const spellsChanged = (action) => {
        setTimeout(() => {
            characterSpellsChanged(spell, action, type);
        }, 150);
    }

    const {
        name, level, school,
        castingTime, ritual, range,
        components, duration, description,
        atHigherLevels, sourcePage
    } = spell;
    const subheader = school + (ritual ? ' (Ritual)' : '');
    const comps = components.join(', ');
    const color = userData.getSpellClassColor(character, spell);

    const handleClick = () => {
        setIsOpen(!isOpen);
    }

    const atHigher = () => {
        if(atHigherLevels !== '') {
            return (
                <React.Fragment>
                    <Typography style={{color: colors.red, textAlign: 'center', marginTop: 15, fontSize: 14}}>At Higher Levels</Typography>
                    <Divider style={{backgroundColor: colors.yellow}} />
                    <Typography style={{fontSize: 14}}>{atHigherLevels}</Typography>
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            <ListItem button onClick={handleClick}>
                <ListItemAvatar>
                    <Avatar style={{backgroundColor: color}}>{level}</Avatar>
                </ListItemAvatar>
                <ListItemText
                    style={{margin: 0}}
                    primary={<Typography color="secondary">{name}</Typography>}
                    secondary={subheader} />
                {isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Paper style={{padding: 8, fontSize: 14}}>
                    <Grid container>
                        <Grid xs={12} item>{translate('spellDetail.castingLabel')}: <strong>{castingTime}</strong></Grid>
                        <Grid xs={12} item>{translate('spellDetail.rangeLabel')}: <strong>{range}</strong></Grid>
                        <Grid xs={12} item>{translate('spellDetail.durationLabel')}: <strong>{duration}</strong></Grid>
                        <Grid xs={12} item>{translate('spellDetail.componentsLabel')}: <strong>{comps}</strong></Grid>
                    </Grid>
                    <div style={{marginTop: 10}}>
                        <Typography style={{fontSize: 14}}>{description}</Typography>
                        {atHigher()}
                        <Typography style={{fontSize: 11, fontStyle: 'italic', color: colors.gray, marginTop: 20}}>{sourcePage}</Typography>
                    </div>
                    <CardActions className="card-actions">
                        <SpellActions character={character} spell={spell} type={type} characterSpellsChanged={spellsChanged} />
                    </CardActions>
                </Paper>
            </Collapse>
        </React.Fragment>       
    )
}

const prepared = 'prepared';
const known = 'known';

const SpellActions = ({character, spell, type, characterSpellsChanged}) => {
    const isPrepared = userData.isPrepared(character.name, spell);

    const removePreparedClick = () => {
        userData.removePreparedSpells(character.name, [spell]);
        characterSpellsChanged('remove');
    }
    const addPreparedClick = () => {
        userData.addPreparedSpells(character.name, [spell]);
        characterSpellsChanged('add');

    }
    const removeKnownClick = () => {
        userData.removeKnownSpells(character.name, [spell]);
        if(isPrepared)
            userData.removePreparedSpells(character.name, [spell]);
        characterSpellsChanged('remove');
    }

    if(type === prepared) {
        return (
            <React.Fragment>
                <ProgressButton key="removeP" icon={<RemoveCircleIcon />} variant="outlined" text={translate('editCharacter.removeFromPrepared')} onClick={removePreparedClick} style={{marginLeft: 'auto'}} />
            </React.Fragment>
        )
    } else if(type === known) {
        if(isPrepared) {
            return (
                <React.Fragment>
                    <ProgressButton key="removePK" icon={<RemoveCircleIcon />} variant="outlined" text={translate('editCharacter.removeFromKnown')} onClick={removeKnownClick} style={{marginLeft: 'auto'}} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <ProgressButton key="addP" icon={<AddCircleIcon />} variant="contained" text={translate('editCharacter.addToPrepared')} onClick={addPreparedClick} />
                    <ProgressButton key="removeK" icon={<RemoveCircleIcon />} variant="outlined" text={translate('editCharacter.removeFromKnown')} onClick={removeKnownClick} style={{marginLeft: 'auto'}} />
                </React.Fragment>
            )
        }
    }
}

export default SpellListItem
