import React, { Component } from 'react'
import { SwipeableDrawer, IconButton } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from '../../helpers/JsFunctions'
import { colors } from '../../helpers/Theme'
import SpellDetailItem from '../details/SpellDetailItem'
import SpellDetailNotFound from '../details/SpellDetailNotFound'

export class CharacterSpellDetail extends Component {
    state = {
        open: false,
        spell: null
    }

    setOpen = (isOpen) => this.setState({open: isOpen});
    setSpell = (s) => this.setState({spell: s});

    spellSelected = (s) => {
        this.setSpell(s);
        this.setOpen(true);
    }

    componentDidMount() {
        this.props.spellSelected(this.spellSelected);
    }

    render() {
        return <CharacterSpellDetailUI open={this.state.open} setOpen={this.setOpen} spell={this.state.spell} />
    }
}

const CharacterSpellDetailUI = ({open, setOpen, spell}) => {
    const isMobile = useMediaQuery({query: isMobileQuery});

    const body = () => {
        if(spell === null || spell.name === '') return <SpellDetailNotFound />;
        return <SpellDetailItem match={{params: { spellName: spell !== null ? spell.urlFriendlyName : '' }}} />;
    }

    if(isMobile) {
        return (
            <SwipeableDrawer open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} anchor="right" SlideProps={{style: {width: '100%'}}}>
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center', height: 48, backgroundColor: colors.lightGray}}>
                    <IconButton onClick={() => setOpen(false)}>
                        <ChevronRightIcon />
                    </IconButton>
                </div>
                {body()}
            </SwipeableDrawer>
        )
    } else {
        return body();
    }
}

export default CharacterSpellDetail