import React from 'react'
import { Chip } from '@material-ui/core'
import { MTableToolbar } from 'material-table'

import MultiSelect from '../../controls/MultiSelect'
import { getStringTranslation } from '../../multiLanguage/Translator'
import { colors } from '../../../helpers/Theme'
import { allClasses, allDamageTypes, allBooks } from '../../../data/GameData'

const CharacterSpellsTableToolbar = ({ toolbarProps, toggleSelection, filterType, filteredDamageTypes, showFavourites, showClassSelection, 
    filterClass, filteredClasses, isOnlyFavourites, filterBook, selectedBook }) => {
    const damageTypes = allDamageTypes.map(x => getStringTranslation('spell.damageType.' + x.toLowerCase()));
    const classes = allClasses.map(x => getStringTranslation('classes.' + x.toLowerCase()));
    const books = allBooks.map(x => getStringTranslation('books.' + x.toLowerCase().split(' ').join(''))).filter(x => x !== '');

    const favouriteLabel = isOnlyFavourites ? getStringTranslation('table.unFavouriteSelection') : getStringTranslation('table.favouriteSelection');

    return (
        <div style={{padding: '0px 10px'}}>
            <MTableToolbar {...toolbarProps} />
            <Chip label={getStringTranslation('editCharacter.addSelectedSpellsLabel')} clickable={true} color="secondary" onClick={toggleSelection} style={chipStyle} />
            <Chip label={favouriteLabel} clickable={true} onClick={showFavourites} style={{...chipStyle, ...favouriteChip}} />
            <br />
            <MultiSelect label={getStringTranslation('table.filterDamageType')} id="filter-damage-type" options={damageTypes} filterType={filterType} selected={filteredDamageTypes} />
            {showClassSelection && 
                <MultiSelect label={getStringTranslation('table.filterClass')} id="filter-class" options={classes} filterType={filterClass} selected={filteredClasses} />}
            <MultiSelect
                label={getStringTranslation('table.filterBook')}
                id="filter-book"
                options={books}
                filterType={filterBook}
                selected={selectedBook} />
        </div>
    )
}

CharacterSpellsTableToolbar.defaultProps = {
    showClassSelection: false
}

const chipStyle = {
    height: 36, 
    margin: '6px 10px'
}

const favouriteChip = {
    backgroundColor: colors.goldYellow
}

export default CharacterSpellsTableToolbar
