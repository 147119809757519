import React from 'react'
import { RadioGroup, Radio, FormControl, FormControlLabel, Fab } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';

export default function SpellCarouselLevelSelection({character, filterByLevel, availableLevels, selectedLevel, editCharacterStats,
    cardVisualizationType, changeCardVisualizationType}) {
    const handleChange = (e) => {
        const val = e.target.value;
        filterByLevel(val);
    }

    if(availableLevels.length === 0) return <React.Fragment />

    const getLabel = (level) => level == -1 ? 'All' : level;

    return (
        <div style={{display: 'flex'}}>
            <FormControl component="fieldset" style={{padding: '0 10px'}}>
                <RadioGroup value={selectedLevel} onChange={handleChange} row>
                    {availableLevels.map((x, i) => {
                        return (
                            <FormControlLabel
                                style={{marginLeft: 4, marginRight: 4}}
                                key={i}
                                value={x}
                                control={<Radio color="secondary" checked={selectedLevel == x} style={{padding: 2}} />}
                                label={getLabel(x)}
                                labelPlacement="top" />
                        )
                    })}
                </RadioGroup>
            </FormControl>
            <div style={{marginLeft: 'auto', padding: '10px 10px'}}>
                <FormControl style={{flexDirection: 'row'}}>
                    <Fab style={fab} size="small" aria-label="change-visualization" title="Change visualization" onClick={changeCardVisualizationType}>
                        {cardVisualizationType === 'cards' ? <ViewHeadlineIcon /> : <ViewCarouselIcon />}
                    </Fab>
                    <Fab style={fab} color="secondary" size="small" aria-label="edit" title="Edit character" onClick={() => editCharacterStats(character)}>
                        <EditIcon />
                    </Fab>
                </FormControl>
            </div>
        </div>
    )
}

const fab = {
    margin: 5
}
