import counterpart from 'counterpart'
import spells_en from './Spells.en'
import spells_it from './Spells.it'

//ritorna tutte le spell (in base alla lingua selezionata)
export const getSpells = (lang) => {
  if(!lang)
    lang = counterpart.getLocale();
  switch(lang) {
    case 'en': return spells_en;
    case 'it': return spells_it;
    default: return spells_en;
  }
}

//ritorna tutti i nomi delle spell (in base alla lingua selezionata)
export const getSpellEntries = () => {
  switch(counterpart.getLocale()) {
    case 'en': 
      return spells_en.map(x => x.name);
    case 'it': 
      return spells_it.map(x => x.name);
    default: 
      return spells_en;
  }
}

//ritorna tutti i dati di una spell dal suo nome-url
//quando premo su unaa spell navigo alla pagina con l'url, da quello risalgo alla spell che devo visualizzare
export const getSpellData = (urlName) => {
  let hit = getSpells().find(x => x.urlFriendlyName === urlName);
  if(hit === undefined) {
    //se ha caricato il sito con un url diretto la lingua è quella di default quindi se ha ricercato con un link diverso dalla lingua di default, non trova la spell
    //ricerco quindi la spell su tutte le lingue
    hit = getSpells('en').find(x => x.urlFriendlyName === urlName);
    if(hit === undefined)
      hit = getSpells('it').find(x => x.urlFriendlyName === urlName);
  }
  return hit;
}