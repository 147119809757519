import React, { Component } from 'react'
import ExportCardRenderer from './card/ExportCardRenderer'
import ExportListRenderer from './list/ExportListRenderer'
import { spellbookType } from '../../helpers/JsFunctions'

export class ExportRender extends Component {
    state = {
        spellbookType: spellbookType.card
    }

    spellbookTypeChanged = (type) => {
        if(this.state.spellbookType !== type)
            this.setState({spellbookType: type});
    }

    componentDidMount() {
        this.props.spellbookTypeChanged(this.spellbookTypeChanged);
    }

    render() {
        if(this.state.spellbookType === spellbookType.card) {
            return (
                <ExportCardRenderer groupedSpells={this.props.groupedSpells} setToPrint={this.props.setToPrint} dimensionUpdated={this.props.dimensionUpdated} />
            );
        } else if(this.state.spellbookType === spellbookType.list) {
            return (
                <ExportListRenderer groupedSpells={this.props.groupedSpells} setToPrint={this.props.setToPrint} dimensionUpdated={this.props.dimensionUpdated} />
            );
        }
    }
}

export default ExportRender
