import React, { Component } from 'react'
import { Drawer, Paper, IconButton, Collapse, BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { useMediaQuery } from 'react-responsive'
import { isMobileQuery } from '../../helpers/JsFunctions'
import { colors } from '../../helpers/Theme'
import { translate, getStringTranslation } from '../multiLanguage/Translator'
import { getSpells } from '../../data/SpellsData'
import StatusMessage, { variants } from '../controls/StatusMessage'
import PreparedSpells from './pages/PreparedSpells'
import KnownSpells from './pages/KnownSpells'
import AllSpells from './pages/AllSpells'
import userData from '../../data/UserData'

export class EditCharacter extends Component {
    state = {
        isOpen: false,
        character: null
    }

    showEditor = (character) => {
        this.setState({isOpen: true, character: character});
    }

    setOpen = (isOpen) => this.setState({isOpen: isOpen});

    componentDidMount() {
        this.props.showPage(this.showEditor);
        this.props.forceClose(() => {
            this.setState({isOpen: false})
        });
    }

    render() {
        if(this.state.character !== null) {
            const character = userData._getCharacter(this.state.character.name);
            if(character !== null) {
                return <EditCharacterUI 
                    isOpen={this.state.isOpen} 
                    setOpen={this.setOpen} 
                    character={character}
                    editCharacterStats={this.props.editCharacterStats} />
            }
        }
        return <React.Fragment />
    }
}


const EditCharacterUI = ({isOpen, setOpen, character, editCharacterStats}) => {
    const isMobile = useMediaQuery({query: isMobileQuery});
    if(isMobile) {
        return (
            <Drawer open={isOpen} onClose={() => setOpen(false)} anchor="right" SlideProps={{style: {width: '100%'}}}>
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center', height: 48, backgroundColor: colors.lightGray}}>
                    <IconButton onClick={() => setOpen(false)}>
                        <ChevronRightIcon />
                    </IconButton>
                </div>
                <Character key={character != null ? character.name : '-1'} character={character} editCharacterStats={editCharacterStats} />
            </Drawer>
        )
    } else {
        return (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Paper>
                    <Character key={character != null ? character.name : '-1'} character={character} editCharacterStats={editCharacterStats} />
                </Paper>
            </Collapse>
        )
    }
}


class Character extends Component {
    state = {
        page: 0,
        allSpells: getSpells(),
        showedSpells: getSpells(),
        knownSpells: userData.getKnownSpells(this.props.character.name),
        preparedSpells: userData.getPreparedSpells(this.props.character.name),
        cardVisualizationType: 'cards'
    }

    setPage = (i) => this.setState({page: i});

    spellSelected = (s) => this.showSelectedSpell(s);

    changeSpellSelection = (rows) => {
        this.characterSelectedSpells = rows;
    }
    addSelectionToCharacterSpells = () => {
        if(this.characterSelectedSpells.length === 0) {
            this.showMessage(getStringTranslation('editCharacter.spellsAddedNotEnoughSpells'), variants.info);
            return;
        }

        const { name } = this.props.character;
        userData.addKnownSpells(name, this.characterSelectedSpells);
        userData.addPreparedSpells(name, this.characterSelectedSpells);
        this.setState({knownSpells: userData.getKnownSpells(name), preparedSpells: userData.getPreparedSpells(name)});
        this.showMessage(getStringTranslation('editCharacter.spellsAddedSuccess'), variants.success, 'top');
    }

    characterSpellsChanged = (spell, action, type) => {
        const { character } = this.props;
        let known = userData.getKnownSpells(character.name);
        let prep = userData.getPreparedSpells(character.name);
        this.setState({knownSpells: known, preparedSpells: prep});
    }

    changeCardsVisualizationType = () => {
        if(this.state.cardVisualizationType === 'cards') {
            this.setState({cardVisualizationType: 'list'});
        } else {
            this.setState({cardVisualizationType: 'cards'});
        }
    }

    componentDidMount() {
        this.characterSelectedSpells = [];
    }

    render() {
        const { allSpells, preparedSpells, knownSpells, page } = this.state;
        return (
            <React.Fragment>
                <CharacterUI 
                    character={this.props.character}
                    page={page}
                    setPage={this.setPage}
                    showSelectedSpell={s => this.showSelectedSpell = s}
                    spellSelected={this.spellSelected}
                    changeSelectedSpells={this.changeSpellSelection}
                    addSelectionToCharacterSpells={this.addSelectionToCharacterSpells}
                    characterSpellsChanged={this.characterSpellsChanged}
                    allSpells={allSpells}
                    preparedSpells={preparedSpells}
                    knownSpells={knownSpells}
                    editCharacterStats={this.props.editCharacterStats}
                    cardVisualizationType={this.state.cardVisualizationType}
                    changeCardsVisualizationType={this.changeCardsVisualizationType} />
                <StatusMessage show={s => this.showMessage = s} />
            </React.Fragment>
        );
    }
}


const CharacterUI = ({character, page, setPage, showSelectedSpell, spellSelected, changeSelectedSpells, addSelectionToCharacterSpells, 
    characterSpellsChanged, allSpells, preparedSpells, knownSpells, editCharacterStats, cardVisualizationType, changeCardsVisualizationType}) => {
    const isMobile = useMediaQuery({query: isMobileQuery});

    const navigation = (
        <BottomNavigation
            value={page}
            onChange={(e, n) => {setPage(n)}}
            showLabels>
                <BottomNavigationAction label={translate('editCharacter.preparedPage')} />
                <BottomNavigationAction label={translate('editCharacter.knownPage')} />
                <BottomNavigationAction label={translate('editCharacter.allPage')} />
        </BottomNavigation>
    );

    const getHeight = () => isMobile ? 'calc(100vh - 48px)' : 'calc(100vh - 98px)';
    const getPage = () => {
        switch(page) {
            default:
            case 0: return <PreparedSpells 
                character={character}
                spells={preparedSpells} 
                characterSpellsChanged={characterSpellsChanged} 
                editCharacterStats={editCharacterStats}
                cardVisualizationType={cardVisualizationType}
                changeCardVisualizationType={changeCardsVisualizationType} />
            case 1: return <KnownSpells 
                character={character}
                spells={knownSpells} 
                characterSpellsChanged={characterSpellsChanged}
                editCharacterStats={editCharacterStats}
                cardVisualizationType={cardVisualizationType}
                changeCardVisualizationType={changeCardsVisualizationType} />
            case 2: return <AllSpells 
                character={character} 
                spellSelected={spellSelected} 
                spells={allSpells} 
                changeSelectedSpells={changeSelectedSpells}
                toggleSelection={addSelectionToCharacterSpells}
                showSelectedSpell={showSelectedSpell} />
        }
    }

    if(isMobile) {
        return (
            <div style={{height: getHeight(), display: 'flex', flexDirection: 'column', backgroundColor: colors.lightGray}}>
                {getPage()}
                {navigation}
            </div>
        )
    } else {
        return (
            <div style={{height: getHeight(), display: 'flex', flexDirection: 'column', backgroundColor: colors.lightGray}}>
                {getPage()}
                {navigation}
            </div>
        )   
    }
}

export default EditCharacter
