import React from 'react'
import { Card, CardHeader, CardContent, CardActions, Avatar, Typography, Divider, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import ProgressButton from '../../controls/ProgressButton'
import { translate } from '../../multiLanguage/Translator'
import { colors } from '../../../helpers/Theme'
import userData from '../../../data/UserData'


//type: prepared | known
const SpellCard = ({character, spell, type, characterSpellsChanged}) => {
    const [open, setOpen] = React.useState(true);

    const spellsChanged = (action) => {
        setTimeout(() => {
            characterSpellsChanged(spell, action, type);
        }, 300);
    }

    const {
        name, level, school,
        castingTime, ritual, range,
        components, duration, description,
        atHigherLevels, sourcePage
    } = spell;
    const subheader = school + (ritual ? ' (Ritual)' : '');
    const comps = components.join(', ');
    const color = userData.getSpellClassColor(character, spell);

    const atHigher = () => {
        if(atHigherLevels !== '') {
            return (
                <React.Fragment>
                    <Typography style={{color: colors.red, textAlign: 'center', marginTop: 15}}>At Higher Levels</Typography>
                    <Divider style={{backgroundColor: colors.yellow}} />
                    <Typography>{atHigherLevels}</Typography>
                </React.Fragment>
            );
        }
    }

    const summary = (
        <ExpansionPanel expanded={open} onChange={(e, isExp) => setOpen(isExp)}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{translate('spellDetail.showDetails')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{padding: 8}}>
                <Grid container>
                    <Grid xs={12} item>{translate('spellDetail.castingLabel')}: <strong>{castingTime}</strong></Grid>
                    <Grid xs={12} item>{translate('spellDetail.rangeLabel')}: <strong>{range}</strong></Grid>
                    <Grid xs={12} item>{translate('spellDetail.durationLabel')}: <strong>{duration}</strong></Grid>
                    <Grid xs={12} item>{translate('spellDetail.componentsLabel')}: <strong>{comps}</strong></Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );

    return (
        <Card className="scroll-content">
            <CardHeader 
                className="card-header"
                title={<Typography variant="h6" color="secondary">{name}</Typography>}
                subheader={subheader}
                avatar={<Avatar style={{backgroundColor: color}}>{level}</Avatar>}
                disableTypography={true}/>
            <CardContent className="card-content">
                {summary}
                <Typography>{description}</Typography>
                {atHigher()}
                <Typography style={{fontSize: 12, fontStyle: 'italic', color: colors.gray, marginTop: 20}}>{sourcePage}</Typography>
            </CardContent>
            <CardActions className="card-actions">
                <SpellCardActions character={character} spell={spell} type={type} characterSpellsChanged={spellsChanged} />
            </CardActions>
        </Card>
    )
}


const prepared = 'prepared';
const known = 'known';

const SpellCardActions = ({character, spell, type, characterSpellsChanged}) => {
    const isPrepared = userData.isPrepared(character.name, spell);

    // const castClick = () => {
    //     //todo
    // }
    const removePreparedClick = () => {
        userData.removePreparedSpells(character.name, [spell]);
        characterSpellsChanged('remove');
    }
    const addPreparedClick = () => {
        userData.addPreparedSpells(character.name, [spell]);
        characterSpellsChanged('add');

    }
    const removeKnownClick = () => {
        userData.removeKnownSpells(character.name, [spell]);
        if(isPrepared)
            userData.removePreparedSpells(character.name, [spell]);
        characterSpellsChanged('remove');
    }

    if(type === prepared) {
        return (
            <React.Fragment>
                {/* <Button variant="contained" color="secondary" size="small" onClick={castClick}>{translate('editCharacter.castSpell')}</Button> */}
                <ProgressButton key="removeP" icon={<RemoveCircleIcon />} variant="outlined" text={translate('editCharacter.removeFromPrepared')} onClick={removePreparedClick} style={{marginLeft: 'auto'}} />
            </React.Fragment>
        )
    } else if(type === known) {
        if(isPrepared) {
            return (
                <React.Fragment>
                    <ProgressButton key="removePK" icon={<RemoveCircleIcon />} variant="outlined" text={translate('editCharacter.removeFromKnown')} onClick={removeKnownClick} style={{marginLeft: 'auto'}} />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <ProgressButton key="addP" icon={<AddCircleIcon />} variant="contained" text={translate('editCharacter.addToPrepared')} onClick={addPreparedClick} />
                    <ProgressButton key="removeK" icon={<RemoveCircleIcon />} variant="outlined" text={translate('editCharacter.removeFromKnown')} onClick={removeKnownClick} style={{marginLeft: 'auto'}} />
                </React.Fragment>
            )
        }
    }
}


export default SpellCard