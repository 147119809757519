export const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const scrollToRef = (ref) => {
  let scroll = ref.getBoundingClientRect().top;
  if(scroll !== 0)
    window.scroll(0, scroll);
}


export const UMs = {
  cm: 'cm',
  inch: 'inch',
  px: 'px'
}
export const cmToInch = (cm) => {
  return roundNumber(cm / 2.54);
}
export const inchToCm = (inch) => {
  return roundNumber(inch * 2.54);
}
export const pxToInch = (inch) => {
  return roundNumber(inch / 96);
}
export const inchToPx = (inch) => {
  return roundNumber(inch * 96);
}


function roundNumber(num) {    
  return +(Math.round(num + "e+1")  + "e-1");
}


export const spellbookType = {
  list: 'list',
  card: 'card'
}


//ritorna un nuovo array con l'elemento 'item' aggiunto o rimosso in base al fatto se già è presente nella collezione utilizzando come chiave la proprietà 'key'
export const addOrRemove = (source, item, key) => {
  let array = [...source];
  let index = array.map(itm => itm[key]).indexOf(item[key]);
  if(index > -1) {
    array.splice(index, 1);
  } else {
    array.push(item);
  }
  return array;
}

//ritorna un nuovo array con l'elemento item aggiornato se già presente oppure aggiunto
export const addOrUpdate = (source, item, key) => {
  let array = [...source];
  let index = array.map(itm => itm[key]).indexOf(item[key]);
  if(index > -1) {
    array.splice(index, 1);
  }
  array.push(item);
  return array;
}

//ritorna un nuovo array da cui è stato rimosso l'elemento item se trovato
export const remove = (source, item, key) => {
  let array = [...source];
  let index = array.map(itm => itm[key]).indexOf(item[key]);
  if(index > -1) {
    array.splice(index, 1);
  }
  return array;
}

//ritorna un nuovo array con l'elemento item aggiunto se non già presente
export const addIfNew = (source, item, key) => {
  let array = [...source];
  let index = array.map(itm => itm[key]).indexOf(item[key]);
  if(index < 0) {
    array.push(item);
  }
  return array;
}



export const isMobileQuery = '(max-width: 600px)';


export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1);
}